import { number } from 'prop-types';
import { useState, useEffect, useCallback, useRef } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

export type TWindowsSize = {
    width: number;
    height: number;
};

export const useWindowSize = (minWidth = null, maxWidth = null, minHeight = null, maxHeight = null) => {
    const isInit: boolean = typeof window === 'object';

    const getSize = () : TWindowsSize => {
        return {
            width: isInit ? window.innerWidth : null,
            height: isInit ? window.innerHeight : null,
        };
    };

    const [windowSize, setWindowSize] = useState<TWindowsSize>(getSize());

    // @ts-ignore
    useEffect(() => {
        if (!isInit) return false;

        const handleResize = () => {
            const size: TWindowsSize = getSize();

            let update = true;

            do {
                if (maxWidth && size.width > maxWidth) {
                    update = false;
                    break;
                }
                if (minWidth && size.width < minWidth) {
                    update = false;
                    break;
                }
                if (maxHeight && size.height > maxHeight) {
                    update = false;
                    break;
                }
                if (minHeight && size.height < minHeight) {
                    update = false;
                    break;
                }
            } while (false);

            if (update) {
                setWindowSize(size);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [isInit]);

    return windowSize;
};

export type TResponsiveType = 'PC' | 'TABLET' | 'MOBILE';

export const useResponsive = (desktopMin: number = 1280, tabletMin: number = 640) => 
{
    const { width, height } = useWindowSize();

    const deviceType = width >= desktopMin ? 'PC' : width >= tabletMin ? 'TABLET' : 'MOBILE';

    return {
        width,
        height,

        deviceType,

        isMobile: deviceType === 'MOBILE',
        isTablet: deviceType === 'TABLET',
        isPc: deviceType === 'PC',
    }
};

export type TScrollBarChackerState = {
    horizontal: boolean;
    vertical: boolean;
};

export const useScrollBarChecker = (ref: any) => {
    const [hasScrollBar, setHasScrollBar] = useState<TScrollBarChackerState>({
        horizontal: false,
        vertical: false
    });

    useEffect(() => {
        function updateState() {
            const el = ref.current;
            if (el) {
                const boundingClientRect = el.getBoundingClientRect();
                const newState = {
                    horizontal: el.scrollWidth > boundingClientRect.width,
                    vertical: el.scrollHeight > boundingClientRect.height,
                };

                if (hasScrollBar.horizontal != newState.horizontal || hasScrollBar.vertical != newState.vertical) {
                    setHasScrollBar({
                        horizontal: el.scrollWidth > boundingClientRect.width,
                        vertical: el.scrollHeight > boundingClientRect.height,
                    });
                }
            }
        }

        updateState();

        window.addEventListener('resize', updateState);

        return () => window.removeEventListener('resize', updateState);
    }, []);

    return hasScrollBar;
};

export function useInViewport(): { isInViewport: boolean; ref: React.RefCallback<HTMLElement> } {
    const [isInViewport, setIsInViewport] = useState(false);
    const [refElement, setRefElement] = useState<HTMLElement | null>(null);

    const setRef = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            setRefElement(node);
        }
    }, []);

    useEffect(() => {
        if (refElement && !isInViewport) {
            const observer = new IntersectionObserver(
                ([entry]) => entry.isIntersecting && setIsInViewport(true)
            );
            observer.observe(refElement);

            return () => {
                observer.disconnect();
            };
        }
    }, [isInViewport, refElement]);

    return { isInViewport, ref: setRef };
}

export function useOuterClick(callback: (e: any) => void) {
    const callbackRef = useRef<any>();
    const innerRef = useRef<any>();
  
    useEffect(() => { callbackRef.current = callback; });
    
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e: any) {
        if (innerRef.current && callbackRef.current && 
          !innerRef.current.contains(e.target)
        ) callbackRef.current(e);
      }
    }, []);
        
    return innerRef;
}

export type TPreventMode = 'BACK_BTN' | 'RELOAD' | 'BOTH' | 'NONE';

export const usePreventBrowserBackBtn = (preventMode: TPreventMode, onBackBtnEvent?: () => void) => {
    const navigate = useNavigate();
    const refStackCount = useRef(Array(1).fill(0));
    const refNavigateTo = useRef(Array(2).fill(undefined));

    const _clearStack = () => {
        const backAmount = refStackCount.current[0];
        if(backAmount > 0) {
            refStackCount.current[0] -= backAmount;
            navigate(-1 * backAmount);        
        }
    };
    const _navigate = (to: string | number, options?: NavigateOptions) => {
        if(refStackCount.current[0] < 1) {
            if(typeof to == 'number') navigate(to);
            else navigate(to, options);
        }
        else if(preventMode == 'BACK_BTN' || preventMode == 'BOTH') {
            refNavigateTo.current[0] = to;
            refNavigateTo.current[1] = options;
            _clearStack();
        }
    }

    useEffect(() => {
        const handleBeforeUnloadEvent = (e) => {
            e.preventDefault();
            e.returnValue = "";
        };
        const handlePopstateEvent = (e) => {
            if(refNavigateTo.current[0] != null) {
                if(typeof refNavigateTo.current[0] == 'number') navigate(refNavigateTo.current[0]);
                else navigate(refNavigateTo.current[0], refNavigateTo.current[1]);
                return;
            }

            navigate(1);

            if(onBackBtnEvent) onBackBtnEvent();
        };
    
        if(preventMode == 'BACK_BTN' || preventMode == 'BOTH')
        {
            if(refStackCount.current[0] < 1) {
                window.history.pushState(null, "", window.location.href);
                refStackCount.current[0]++;
            }

            window.addEventListener("popstate", handlePopstateEvent);
        }
        
        if(preventMode == 'RELOAD' || preventMode == 'BOTH') window.addEventListener("beforeunload", handleBeforeUnloadEvent);

        return () => {
          if(preventMode == 'BACK_BTN' || preventMode == 'BOTH') window.removeEventListener("popstate", handlePopstateEvent);
          if(preventMode == 'RELOAD' || preventMode == 'BOTH') window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
        };
    }, [preventMode]);

    return {
        navigate: _navigate,
    };
};

