import React, { useEffect, useState } from "react";
import { useResponsive } from "@src/modules/hlibs/react-web/hook";
import { setRecoil } from "@src/store/recoil";
import { setDefaultAutoScale, setDevScreenSize, setPublishScreenSize } from "@src/modules/hlibs/react-web/hstyles";
import { useLocation } from "react-router-dom";
import { Utils } from "@src/modules/hlibs";

export type TResponsiveProps = {
    
};

const Responsive: React.FunctionComponent<TResponsiveProps> = (props) => {

    const { deviceType, width, height } = useResponsive();
    
    const location = useLocation();

    useEffect(() => 
    { 
        setRecoil('deviceInfo', { type: deviceType, width, height }); 

        if(deviceType == 'TABLET') 
        { 
            setDevScreenSize(1024); 
            // setDefaultAutoScale(true); 
        }
        else if(deviceType == 'MOBILE') 
        { 
            setDevScreenSize(384); 
            // setDefaultAutoScale(true); 
        }
        else 
        { 
            setDevScreenSize(1280); 
            setDefaultAutoScale(false); 
        }
    }, [deviceType, location.pathname, width, height]);

    setPublishScreenSize(width);

    return null;
};

export default Responsive;
