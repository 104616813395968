import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from "recoil-nexus";
import './App.css';
import { AppRouter } from '@screens/index';
import { QueryClientProvider } from "react-query";
import { queryClient } from '@modules/react-query';

function App() {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <QueryClientProvider client={queryClient}>
        <AppRouter/>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
