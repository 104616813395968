import * as Crypto from './crypto';
import * as Config from './config';
import { date2str } from './utils';
import moment from 'moment';

type TExpiredData = {
    data: object | string;
    type: string;
    encrypted: boolean;
    expiredTime: string;
};

export default class ExpireData {
    static DefaultSecret = Config.CRYPTO.AES_SECRET;

    static makeData(data: object | string, expireSec: number | null | undefined = null): string {
        return this.encrypt(data, expireSec, null);
    }

    static encrypt(data: object | string, expireSec: number | null | undefined = null, aesSecret: string | null | undefined = ExpireData.DefaultSecret): string {
        let expiredTime = null;

        if (expireSec) {
            let dateExpired = new Date();

            dateExpired.setSeconds(moment().toDate().getSeconds() + expireSec);

            expiredTime = date2str(dateExpired, 'yyyy-MM-dd hh:mm:ss');
        }

        let dataEnc: TExpiredData = {
            data: aesSecret ? Crypto.SHAAES.encrypt(typeof data == 'object' ? JSON.stringify(data) : data, aesSecret) : data,
            type: typeof data,
            encrypted: aesSecret ? true : false,
            expiredTime,
        };

        return JSON.stringify(dataEnc);
    }

    static readData(encodedData: string, ignoreExpireTime: boolean = false) {
        return this.decrypt(encodedData, null, ignoreExpireTime);
    }

    static decrypt(encodedData: string, aesSecret: string | null | undefined = ExpireData.DefaultSecret, ignoreExpireTime: boolean = false) {
        if (!encodedData) return encodedData;

        let dataDec = null;

        try {
            let dataObj: TExpiredData = JSON.parse(encodedData);

            try {
                if (!ignoreExpireTime && dataObj.expiredTime) {
                    let dateExpired = moment(dataObj.expiredTime).toDate();

                    let dateNow = moment().toDate();

                    if (dateExpired < dateNow) {
                        return null;
                    }
                }

                dataDec = dataObj.data && dataObj.encrypted && typeof dataObj.data == 'string' && aesSecret ? Crypto.SHAAES.decrypt(dataObj.data, aesSecret) : dataObj.data;

                if (dataObj.encrypted && dataObj.type === 'object' && typeof dataDec == 'string') {
                    dataDec = JSON.parse(dataDec);
                }
            } catch (e) {
                dataDec = dataObj.data;
            }
        } catch (e) {
            dataDec = null;
        }

        return dataDec;
    }
}
