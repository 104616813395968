import AppConfig from '@app/config';

const Config = AppConfig as any;

const LOG_ENABLE = Config.MODE == 'RELEASE' ? false : true;

export const log = (message: string | object) =>
{
    if(Array.isArray(message) || typeof message == 'object')
    {
        message = JSON.stringify(message);
    }

    if(LOG_ENABLE == true)
    {
        console.log("LOG : " + message);
    }
};

export const error = (errorMessage: string | object) =>
{
    if(Array.isArray(errorMessage) || typeof errorMessage == 'object')
    {
        errorMessage = JSON.stringify(errorMessage);
    }

    if(LOG_ENABLE == true)
    {
        console.error("ERROR : " + errorMessage);
    }
};

export const exception = (exception: any) =>
{
    if(LOG_ENABLE == true)
    {
        console.error("EXCEPTION : " + exception.stack);
    }
};

export const event = (message: string | object, extra: any) =>
{
    if(Array.isArray(message) || typeof message == 'object')
    {
        message = JSON.stringify(message);
    }

    if(LOG_ENABLE == true)
    {
        console.log("EVENT : message = " + message + " | extra = " + extra);
    }
};