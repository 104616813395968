import { waitUntil as _waitUntil, Predicate, Options } from 'async-wait-until';
import moment from 'moment';
import _ from 'lodash';
import DateUtil from './utils.date';

export { moment, DateUtil };

export const waitUntil = (predicate: Predicate<any>, options: number | Options | undefined = { timeout: 60 * 1000 }, intervalBetweenAttempts: number | undefined = null) => _waitUntil(predicate, options, intervalBetweenAttempts);

export const delay = (time: number) => new Promise(res=>setTimeout(res,time));

export const date2str = DateUtil.date2str;

export const dateTime2str = DateUtil.dateTime2str;

let _uniqueKeyCur = 0;

export const sequencialUniqueKey = (header: string = 'hlib-uk') => {
  return header + '_' + ++_uniqueKeyCur;
};

export const replaceAll = (str: string, searchStr: string, replaceStr: string) =>
{
    return str.split(searchStr).join(replaceStr);
};

export const randInt = (min: number, max: number) =>
{
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const createRandomString = (length: number = 5, chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') =>
{
    let text = '';

    for(let i = 0; i < length; i++)
    {
        let randIndex = randInt(0, chars.length - 1);

        text += chars.charAt(randIndex);
    }

    return text;
};

export const getAge = (birthday: Date, bAmericanAge: boolean = true) =>
{
    let today = moment().toDate();

    if(bAmericanAge == true)
    {       
        let birth = date2str(birthday, "yyyy-MM-dd");
        let date = moment().toDate();
        let year = date.getFullYear();    
        let month: number | string = (date.getMonth() + 1);    
        let day: number | string = date.getDate();       
    
        if (Number(month) < 10) month = '0' + String(month);    
        if (Number(day) < 10) day = '0' + String(day);
    
        let monthDay: string = month.toString() + day.toString();
    
        birth = birth.replace('-', '').replace('-', '');
    
        let birthdayy: number = parseInt(birth.substr(0, 4));
        let birthdaymd: string = birth.substr(4, 4);

        return monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;
    }

    return today.getFullYear() - birthday.getFullYear() + 1;
};

export const getMonthAge = (birthday: Date) =>
{
    return DateUtil.getDiffMonths(new Date(), birthday);
};

export const filterObject = (obj: object, filter: Array<string>) =>
{
    if(obj == null || filter == null)
        return obj;

    let filteredObj = {};

    let remainObj = { ...obj };

    for(const keyCur of filter)
    {
        if(keyCur in obj)
        {
            filteredObj[keyCur] = obj[keyCur];

            delete remainObj[keyCur];
        }
    }	

    return [filteredObj, remainObj];
};

export const colFilter = (obj: object, filter: Array<string>, getRemain: boolean = false) =>
{
    if(obj == null || filter == null)
        return obj;

    const filteredObj = filterObject(obj, filter);

    return getRemain ? filteredObj[1] : filteredObj[0];
};

export const checkKeyExists = (obj: object, keyArray: Array<string>) =>
{
    if(obj == null || keyArray == null || typeof obj != 'object')
        return false;

    for(let i = 0; i < keyArray.length; i++)
    {
        let keyCur = keyArray[i];

        if(keyCur in obj == false)
            return false;
    }	

    return true;
};

export const replaceAt = function(input: string, index: number, character: string)
{
    return input.substr(0, index) + character + input.substr(index+character.length);
}

export const toPhoneNumString = (str: string) =>
{
    if(!str) return str;
    let number = str.replace(/[^0-9]/g, "");
    let phone = "";
    const isSeoul = number?.indexOf('02') == 0;
    const isOrg = number?.indexOf('15') == 0;

    if(number.length < 4) {
        return number;
    } else if(number.length < (isSeoul ? 6 : isOrg ? 8 : 7)) {        
        phone += number.substr(0, isSeoul ? 2 : isOrg ? 4 : 3);
        phone += "-";
        phone += number.substr(isSeoul ? 2 : isOrg ? 4 : 3);
    } else if(!isOrg && number.length < (isSeoul ? 10 : 11)) {
        phone += number.substr(0, isSeoul ? 2 : 3);
        phone += "-";
        phone += number.substr(isSeoul ? 2 : 3, 3);
        phone += "-";
        phone += number.substr(isSeoul ? 5 : 6);
    } else {
        if(isOrg)
        {
            phone += number.substr(0, 4);
            phone += "-";
            phone += number.substr(4);
        }
        else
        {
            phone += number.substr(0, isSeoul? 2 : 3);
            phone += "-";
            phone += number.substr(isSeoul ? 2 : 3, 4);
            phone += "-";
            phone += number.substr(isSeoul ? 6 : 7);    
        }
    }    

    return phone;
};

export const isIDString = (str: string, minLength: number = 6, maxLength: number = 20) =>
{
    return str && str.length >= minLength && str.length <= maxLength ? true : false;
};

export const isEmailString = (str: string) => 
{
  return str && str.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i) ? true : false;
};

export const isPasswordString = (str: string, minLength: number = 8, maxLength: number = 16, checkAlphabet: boolean = true, checkNumber: boolean = true, checkSpecialChar: boolean = false) =>
{
    if(!str || str.length < minLength || str.length > maxLength) return false;
    if(checkAlphabet && isContainAlphabetChar(str) == false) return false;
    if(checkNumber && isContainNumberChar(str) == false) return false;
    if(checkSpecialChar && isContainSpecialChar(str) == false) return false;

    return true;
}

export const checkPasswordString = (str: string) =>
{
    let validCount = 0;

    const containAlphabet = isContainAlphabetChar(str);
    const containNumber = isContainNumberChar(str);
    const containSpecialChar = isContainSpecialChar(str);

    if(containAlphabet) validCount++;
    if(containNumber) validCount++;
    if(containSpecialChar) validCount++;

    return {
        containAlphabet,
        containNumber,
        containSpecialChar,
        validCount
    };
}

export const isContainAlphabetChar = (str: string) => 
{
  return str && str.match(/.*[A-Za-z].*/) ? true : false;
};

export const isContainNumberChar = (str: string) => 
{
  return str && str.match(/.*[0-9].*/) ? true : false;
};

export const isContainSpecialChar = (str: string) => 
{
  return str && str.match(/[~!@\#$%^&*\()\=\-`.,_+|\\/:;?""<>']/) ? true : false;
};

export const isUrlString = (str: string, includeProtocol: boolean = false) => 
{
    if(!str) return false;
    if(includeProtocol) return str.match(/^(http(s):\/\/.)[-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%._\+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%_\+.~#?&//=]*)$/) ? true : false;
    else return str.match(/^(http:\/\/.)[-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%._\+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%_\+.~#?&//=]*)$/) || str.match(/^(http(s):\/\/.)[-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%._\+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%_\+.~#?&//=]*)$/) || str.match(/^[-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%._\+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9\uac00-\ud7afㄱ-ㅎㅏ-ㅣ@:%_\+.~#?&//=]*)$/) ? true : false;
};

export const replaceBirthString = (str: string) =>
{
    if (!str) return str;
    return str.replace(/[^0-9]/g, '');
}

export const replaceDigitString = (str: string) =>
{
  if(!str) return str;
  return str.replace(/[^0-9]/g, ''); 
}

export const replaceNameString = (str: string, forReplacer: boolean = true) => 
{
  if(!str) return str;
  return forReplacer ? str.replace(/[^가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z ]/g, '') : str.replace(/[^가-힣a-zA-Z ]/g, '');  
}

export const replaceNameStringAllowDigits = (str: string, forReplacer: boolean = true) => 
{
  if(!str) return str;
  return forReplacer ? str.replace(/[^가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z0-9 ]/g, '') : str.replace(/[^가-힣a-zA-Z0-9 ]/g, '');  
}

export const replaceKorNameString = (str: string, forReplacer: boolean = true) => 
{
    if(!str) return str;
    return forReplacer ? str.replace(/[^가-힣ㄱ-ㅎㅏ-ㅣㆍᆢ a-zA-Z~!@\#$%^&*\()\=\-`.,_+|\\/:;?""<>']/g, '') : str.replace(/[^가-힣 ]/g, '');
}
    
export const replaceEngNameString = (str: string, forReplacer: boolean = true) => 
{
    if(!str) return str;
    return forReplacer ? str.replace(/[^a-zA-Z. ]/g, '') : str.replace(/[^a-zA-Z. ]/g, '');  
}
    
export const replaceIDString = (str: string) => 
{
  if(!str) return str;
  return str.replace(/[^a-zA-Z0-9]/g, ''); 
}

export const replaceDecimalPointString = (str: string, decimalPlace: number = 2, applyRound: boolean = false, max: number | null = null) => 
{
  if(!str) return str;

  const val = str.replace(/[^0-9.]/g, '').replace('.', ',').split('.').join('').replace(',', '.');

  if(!val || val.indexOf('.') == val.length - 1) return val;

  const numVal: number = Number(val);
  const numResult = (applyRound ? Math.round(numVal * Math.pow(10, decimalPlace)) : Math.floor(numVal * Math.pow(10, decimalPlace) + 0.01)) / Math.pow(10, decimalPlace);
  if(max != null && numResult > max) return max.toString();
  return numResult.toString(); 
}

export const numberWithCommas = (x: number) =>
{
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0";
};

export const createObjectWithNewKey = (obj: object, newKey: string = "ID") =>
{
    if(obj == null)
        return {};

    let newObj = {};

    for(let key in obj)
    {
        let itemCur = obj[key];

        newObj[itemCur[newKey] + ""] = itemCur;
    }

    return newObj;
};

export const createObjectWithNewKeyFromArray = (arr: Array<object>, newKey: string = "ID") =>
{
    if (arr == null) return {};

    let newObj = {};
  
    for (let i = 0; i < arr.length; i++) 
    {
        let itemCur = arr[i];
  
        newObj[itemCur[newKey] + ''] = itemCur;
    }
  
    return newObj;
};

export const cloneObject = (obj: object) =>
{
    return _.cloneDeep(obj);
};

// ...args 로 넘어온 argument 들 중 하나라도 null 이거나 "" 인 경우 true 리턴 
export const checkNullOrEmpty = (...args: any[]) =>
{
    let checkParams = [ ...args ];

    for(let i = 0; i < checkParams.length; i++)
    {
        if(checkParams[i] == null || checkParams[i].length < 1)
        {
            return true;
        }
    }

    return false;
};

export const mergeObject = (...objects: any) =>
{
    let objectResult = {};

    for(let objectCur of objects)
    {
        if(objectCur == null)
            continue;
            
        objectResult = { ...objectResult, ...objectCur };
    }
    
    return objectResult;
};

export const deleteNullKeys = (object: object) =>
{
    for(let keyCur in object)
    {
        if(object[keyCur] == null)
        {
            delete object[keyCur];
        }
    }

    return object;
};

// 오브젝트의 키와 값을 맵 테이블 정의대로 변환
export const applyMap = (obj: any, mapTable: any) =>
{
    let result = [];

    let isArray = Array.isArray(obj);

    let list = isArray == true ? obj : [obj];

    for(let itemCur of list)
    {
        let resultCur = {};

        for(let field in itemCur)
        {
            let fieldCur = field;
            let valueCur = itemCur[field];
    
            if(field in mapTable)
            {
                if("key" in mapTable[field])
                {
                    fieldCur = mapTable[field]["key"];
                }
    
                else if("field" in mapTable[field])
                {
                    fieldCur = mapTable[field]["field"];
                }
    
                if("value" in mapTable[field] && valueCur in mapTable[field]["value"])
                {
                    valueCur = mapTable[field]["value"][valueCur];
                }
            }
    
            if(fieldCur.length > 0)
            {
                resultCur[fieldCur] = valueCur;
            }
        }   
        
        result.push(resultCur);
    }

    return isArray == true ? result : result.length > 0 ? result[0] : null;
};


// 디비에 저장된 스트링 타입 데이터 오브젝트로 변환
export const dbDataToObject = (strData: string) =>
{
    let objData = {};

    if(strData != null && strData.length > 0)
    {
        let arrData = strData.split(',');

        for(let i = 0; i < arrData.length; i++)
        {
            let kv = arrData[i].split('=');

            if(kv.length < 2)
                continue;

            let id = kv[0];
            let value = kv[1];

            if(id.length < 1)
                continue;

            objData[id] = value;
        }
    }

    return objData;
};

export const objectToDBData = (obj: object) =>
{
    let strData = '';

    for(let key in obj)
    {
        strData += (strData.length > 0 ? "," : '') + key + "=" + replaceAll(obj[key], ',', '`');
    }

    return strData;
};

export const getClientIP = (ctx: any) =>
{
    const header = ctx && ctx.request && ctx.request.header ? ctx.request.header : null;

    let ipAddr = !header ? null : header['x-real-ip'] ? header['x-real-ip'] : header['x-forwarded-for'] ? header['x-forwarded-for'] : null;

    if(!ipAddr)
    {
        ipAddr = ctx.req.connection.remoteAddress ? ctx.req.connection.remoteAddress : ctx.req.socket.remoteAddress ? ctx.req.socket.remoteAddress : null;
    }

    return ipAddr;
};

export const joinPath = (...args: string[]) =>
{
    let arr = [ ...args ];

    let path = arr.join('/');

    do
    {
        path = path.split('//').join('/');
    } while(path.indexOf('//') >= 0)

    return path.indexOf('https:/') >= 0 ? path.replace('https:/', 'https://') : path.indexOf('http:/') >= 0 ? path.replace('http:/', 'http://') : path;
};

export const dec2hex = (num: number) =>
{
    return num.toString(16);
};

export const hex2dec = (hex: string) =>
{
    return parseInt(hex, 16);
};

export const hexToBytes = (hex: string) =>
{
    for (var bytes = [], c = 0; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
};

export const opacity2hex = (opacity: number) =>
{
    let number = opacity * 255;

    number = number < 0 ? 0 : number > 255 ? 255 : number;

    const hexNum = number.toString(16);

    return hexNum.indexOf('.') ? hexNum.split('.')[0] : hexNum;
};

export const rgba = (r: number, g: number, b: number, a: number) => `rgba(${r},${g},${b},${a})`;

export const hexColorToRgba = (hexColor: string, opacity?: number) =>
{
    if(hexColor?.charAt(0) == '#' && (hexColor.length == 7 || hexColor.length == 9))
    {
        const colorStr = hexColor.replace('#', '');
        const r = parseInt(colorStr.substring(0, 2), 16);
        const g = parseInt(colorStr.substring(2, 4), 16);
        const b = parseInt(colorStr.substring(4, 6), 16);
        const a = opacity ? opacity : colorStr?.length == 8 ? parseInt(colorStr.substring(6, 8), 16) / 255 : 1;

        return `rgba(${r},${g},${b},${a})`;    
    }
    else return hexColor;
};

export const getObjectItemByIndex = (object: object, index: number) =>
{
    if(!object) return null;

    if(Array.isArray(object)) return object[index];
    
    let keys = Object.keys(object);

    return object[keys[index]];
};

export const getFirstObjectItem = (object: object) =>
{
    return getObjectItemByIndex(object, 0);
};

export const reverseArray = (arr: Array<any>) =>
{
    let reversed = JSON.parse(JSON.stringify(arr));

    return reversed.reverse();
};

// object / array / value recursive로 내용 비교. 같으면 true 리턴
// keyArray 가 null 이 아닌 경우 => 둘 다 object 인 경우에 keyArray 의 key 값들만 비교
export const compareRecursive = (obj1: object, obj2: object, keyArray: Array<string> = null) =>
{
    if(obj1 == null || obj2 == null) return _.isEqual(obj1, obj2);
    else if(typeof obj1 == 'object' && typeof obj2 == 'object')
    {
        if(!keyArray && Object.keys(obj1).length != Object.keys(obj2).length) return false;

        const keys = keyArray || Object.keys(obj1);

        if(!keys || keys.length < 1) return _.isEqual(obj1, obj2);
        else
        {
            for(let keyCur of keys)
            {
                if(!compareRecursive(obj1[keyCur], obj2[keyCur])) return false;
            }    

            return true;
        }
    }
    else return _.isEqual(obj1, obj2);
};

// object 에서 key 값들만 분리해서 리턴
export const popKeysFromObject = (keyArray: Array<string>, object: object, removeFromOrigin: boolean = true) =>
{
    if(!keyArray || !object) return {};

    let newObj = {};

    for(let keyCur of keyArray)
    {
        if(keyCur in object)
        {
            newObj[keyCur] = object[keyCur];

            if(removeFromOrigin)
            {
                delete object[keyCur];
            }
        }
    }

    return newObj;
};

export const shuffleArray = (inputArray: Array<any>) =>
{
    return inputArray.sort(()=> Math.random() - 0.5);
};

export const makeArrayGroup = (array: Array<any>, groupMaxCount: number = 2) =>
{
    const arrayGroups = [];

    let groupCur = [];

    for(let i = 0; i < array.length; i++)
    {
        groupCur.push({ value: array[i], index: i });

        if(groupCur.length >= groupMaxCount)
        {
            arrayGroups.push(groupCur);

            groupCur = [];
        }
    }

    if(groupCur.length > 0) arrayGroups.push(groupCur);

    return arrayGroups;
}

export const existsInArray = (value: string, array: Array<string>) =>
{
    return !array || !Array.isArray(array) ? false : array.find((str) => str === value ) == value ? true : false;
};

export const findInArray = (value: any, array: Array<any>) =>
{
    return array.find((data) => data === value ) == value ? true : false;
};

export const makeArray = (size: number, defaultValue: any = null) =>
{
    const array = new Array(size);

    for(let i = 0; i < array.length; i++)
    {
        array[i] = defaultValue;
    }

    return array;
};

export const toGroupArrays = (array: Array<any>, groupSize: number) =>
{
    if(!array || array.length < 1) return array;

    const newArray = [];

    for(let i = 0; i < array.length; i+=groupSize)
    {
        newArray.push(array.slice(i, i + groupSize));
    }

    return newArray;
}

export const round = (num: number, decimalPlace: number = 1) => Math.round(num * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);
export const floor = (num: number, decimalPlace: number = 1) => Math.floor(num * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);
export const ceil = (num: number, decimalPlace: number = 1) => Math.ceil(num * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);

export const numberToDecimalStr = (num: number, fractionDigits: number = 0) => 
{
    let str = num.toString();

    if(str.indexOf('.') < 0) str += '.';

    for(let i = 0; i < fractionDigits; i++) str += '0';

    let parsed = str.split('.');

    return parsed[0] + '.' + parsed[1].substring(0, fractionDigits);
};

export const readFileBase64 = async (url: string) => 
{
    return new Promise(async (res) => {
        const data = await fetch(url);
        const blob = await data.blob();
        const reader = new FileReader();
        reader.onload = () => {
            res(reader.result);
        }
        reader.readAsDataURL(blob);    
    });
};

