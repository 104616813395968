import { useEffect } from "react";
import { useRecoilState as useRecoilStateBase, useRecoilValue as useRecoilValueBase, SetterOrUpdater } from 'recoil';
import { getRecoil as getRecoilBase, setRecoil as setRecoilBase, resetRecoil as resetRecoilBase  } from 'recoil-nexus';
import { Storage } from '@hlibs/index';

import storageAtoms, { TStorageAtomList } from './storage-atoms';
import memoryAtoms, { TMemoryAtomList, TResponsiveType, TDeviceInfo } from './memory-atoms';

export type { TDeviceInfo, TResponsiveType };

export type TAtomList = keyof TStorageAtomList | keyof TMemoryAtomList;

export const atoms = { ...storageAtoms, ...memoryAtoms };

async function updateValue<T>(atomName: TAtomList, value: T)
{
    const savedValue = Storage.getItem(atomName);

    if(value != savedValue)
    {
        Storage.setItem(atomName, value);
    }
};

let _isReady = false;

export const isReady = () => _isReady;

// 스토리지에서 불러오기
export const loadFromStorage = async () =>
{
    for(const keyCur in storageAtoms)
    {
        const data = Storage.getItem(keyCur);

        if(data != getRecoilBase(storageAtoms[keyCur]))
        {
            setRecoilBase(storageAtoms[keyCur], data);
        }
    }

    _isReady = true;
};

// 스토리지에 저장
export const saveToStorage = async () =>
{
    for(const keyCur in storageAtoms)
    {
        Storage.setItem(keyCur, getRecoilBase(storageAtoms[keyCur]));
    }
};

// 스토리지 초기화
export const resetStorage = async () =>
{
    for(const keyCur in storageAtoms)
    {
        Storage.delete(keyCur);

        resetRecoilBase(storageAtoms[keyCur]);
    }
};

export function getRecoil<T>(atomName: TAtomList): T
{
    // @ts-ignore
    return getRecoilBase<T>(atoms[atomName]);
}

export function setRecoil<T>(atomName: TAtomList, value: T)
{
    if(atomName in storageAtoms)
    {
        updateValue(atomName, value);
    }
    
    // @ts-ignore
    return setRecoilBase<T>(atoms[atomName], value);
}

export function resetRecoil(atomName: TAtomList)
{
    if(atomName in storageAtoms)
    {
        Storage.delete(atomName);
    }
    
    return resetRecoilBase(atoms[atomName]);
}

export function useRecoilState<T>(atomName: TAtomList): [T, SetterOrUpdater<T>]
{
    // @ts-ignore
    const [value, setValue] = useRecoilStateBase<T>(atoms[atomName]);

    // storage atom 인 경우 자동 저장
    useEffect(() => 
    {        
        if(atomName in storageAtoms)
        {
            updateValue(atomName, value);
        }
    }, [value]);

    return [value, setValue];
}

export function useRecoilValue<T>(atomName: TAtomList): T
{
    // @ts-ignore
    return useRecoilValueBase<T>(atoms[atomName]);
}

export default class RecoilInstance
{
    static get atoms() { return atoms; }

    static async loadFromStorage() { loadFromStorage() }
    static async saveToStorage() { saveToStorage() }
    static async resetStorage() { resetStorage() }
}