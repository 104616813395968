import React, { CSSProperties, ReactElement } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import Spacing from '../components/Spacing';
import { backgroundColor, mergeProps, mergeStyles, scale } from '@hlibs/react/hstyles';
import * as Utils from '@hlibs//utils';
import ReactLoading from 'react-loading';

export type TLoadingProps = React.HTMLAttributes<HTMLDivElement> & {
  preset?: keyof TPresetList | TPreset;
  presetList?: TPresetList;
  indicator?: ReactElement<any, any>;
  message?: string;
  messageElement?: ReactElement<any, any>;
  style?: CSSProperties;
  messageStyle?: CSSProperties;
  backgroundColor?: string;
  preventBackButton?: boolean;
};

const LoadingPropsKeys = ['preset','presetList','indicator','message','messageElement','style','messageStyle','preventBackButton','backgroundColor'];

type TPreset = {
  props?: TLoadingProps;
  rootStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  messageStyle?: CSSProperties;
};

type TPresetList = {
  default: TPreset;
};

export const DefaultPreset: TPreset = {
  props: {
    indicator: <ReactLoading type={'spin'} color={'rgba(0,0,0,0.1)'} width={50} height={50}/>,
    message: '',
    preventBackButton: true,
    backgroundColor: '#00000000',
  },
  rootStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  boxStyle: {
    paddingLeft: scale(60),
    paddingRight: scale(60),
    paddingTop: scale(30),
    paddingBottom: scale(30),
    borderRadius: scale(15),
    backgroundColor: 'rgba(0,0,0,0)',
    flexDirection: 'column',
  },
  messageStyle: {
    fontSize: scale(15),
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: scale(20),
  },
};

export const Preset: TPresetList = {
  default: DefaultPreset,
};

const PREFIX = 'Hlibs_';

type TLoadingData = {
  visible: boolean;
  props?: TLoadingProps;
};

const initialStates: TLoadingData = { visible: false };

export const loadingDataAtom = atom<TLoadingData>({
  key: PREFIX + 'loadingDataAtom' + Utils.createRandomString(10),
  default: initialStates,
});

export const useLoading = () => {
  const setStates = useSetRecoilState(loadingDataAtom);

  const show = (props: TLoadingProps = {}) => {
    setStates({ visible: true, props });
  };

  const hide = () => {
    setStates(initialStates);
  };

  return {
    show,
    hide,
  };
};

const Loading: React.FunctionComponent<TLoadingProps> = (props) => {
  const { preset = 'default' } = props;

  const presetList = props?.presetList || Preset;

  const selectedPreset = preset && typeof preset == 'string' && preset in presetList ? presetList[preset] : preset && typeof preset != 'string' ? preset : DefaultPreset;

  const isDefaultPreset = selectedPreset == DefaultPreset ? true : false;

  const mergedProps: TLoadingProps = mergeProps(
    isDefaultPreset ? null : DefaultPreset.props,
    selectedPreset.props,
    props,
  );

  const rootStyle: CSSProperties = mergeStyles(
    isDefaultPreset ? null : DefaultPreset.rootStyle,
    selectedPreset.rootStyle,
  );

  const boxStyle: CSSProperties = mergeStyles(
    isDefaultPreset ? null : DefaultPreset.boxStyle,
    selectedPreset.boxStyle,
    mergedProps.style,
  );

  const messageStyle: CSSProperties = mergeStyles(
    isDefaultPreset ? null : DefaultPreset.messageStyle,
    selectedPreset.messageStyle,
    mergedProps.messageStyle,
  );

  const { message, messageElement } = mergedProps;

  const filteredprops = Utils.filterObject(mergedProps, LoadingPropsKeys);

  const rootProps = filteredprops[1];

  return (
    <div {...rootProps} style={mergeProps(rootStyle, { backgroundColor: mergedProps.backgroundColor || '#00000099' })}>
      <div style={boxStyle}>
        <>
          {mergedProps.indicator}
          <Spacing y={20} />
          {messageElement ? (
            messageElement
          ) : message ? (
            <div style={messageStyle}>{message}</div>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default Loading;
