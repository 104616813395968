import _configs from '@config/config.json';

const DefaultMode = 'RELEASE';

const ModeTexts : Array<string> = [
    "RELEASE",
    "DEV"
];

export const ApplyModeConfig = (config: any, Mode: string) => 
{
    if(Mode in config)
    {
        for(let keyCur in config[Mode])
        {
            config[keyCur] = config[Mode][keyCur];
        }
    }

    for(let itemCur of ModeTexts)
    {
        delete config[itemCur];
    }

    for(let keyCur in config)
    {
        if(typeof config[keyCur] == 'object')
        {
            config[keyCur] = ApplyModeConfig(config[keyCur], Mode);
        }
    }

    return config;
};

let Mode = process.env.MODE || process.env.REACT_APP_CUSTOM_MODE ;

Mode = Mode ? ModeTexts.find((str) => { return str === Mode.toUpperCase().trim(); }) || DefaultMode : DefaultMode;

process.env.MODE = Mode;

const configs = ApplyModeConfig(_configs, Mode);

configs.MODE = Mode;

export default configs;