import { atom, RecoilState } from 'recoil';
import { Utils } from '@hlibs/index';

const PREFIX = 'STORAGE_ATOMS_';

export type TStorageAtomList = {

};

// 스토리지에 저장 / 불러오기로 사용 할 atoms
const storageAtoms: TStorageAtomList = {

};

export default storageAtoms;