import React, { CSSProperties, useEffect, useState } from 'react';
import { Property } from 'csstype';
import { scale, getStatusBarHeight, mergeStyles, height } from '@hlibs/react/hstyles';
import { getDefaultAutoScale } from '../hstyles';

export type TAlignType = 'left' | 'center' | 'right' | 'top' | 'middle' | 'bottom' | 'left|top' | 'left|middle' | 'left|bottom' | 'right|top' | 'right|middle' | 'right|bottom' | 'center|top' | 'center|middle' | 'center|bottom';

type StyleExtendedViewProps = {
    autoScale?: boolean | undefined;
    divRef?: React.LegacyRef<HTMLDivElement>;
    backgroundTransparent?: boolean | undefined;
    horizontal?: boolean | undefined;
    vertical?: boolean | undefined;
    fillParentWidth?: boolean | undefined;
    fillParentHeight?: boolean | undefined;
    fillParent?: boolean | undefined;
    fillViewPort?: boolean | undefined;
    fillViewPortWidth?: boolean | undefined;
    fillViewPortHeight?: boolean | undefined;
    fillViewPortAutoResize?: boolean | undefined;
    mb?: number | string | undefined;
    mh?: number | string | undefined;
    ml?: number | string | undefined;
    mr?: number | string | undefined;
    mt?: number | string | undefined;
    mv?: number | string | undefined;
    pb?: number | string | undefined;
    ph?: number | string | undefined;
    pl?: number | string | undefined;
    pr?: number | string | undefined;
    pt?: number | string | undefined;
    pv?: number | string | undefined;
    l?: number | string | undefined;
    t?: number | string | undefined;
    r?: number | string | undefined;
    b?: number | string | undefined;
    align?: TAlignType;
    absolute?: boolean | undefined;
    absoluteFillParent?: boolean | undefined;
    fixed?: boolean | undefined;
    fixedFillParent?: boolean | undefined;
    relative?: boolean | undefined;
    sticky?: boolean | undefined;
    stickyTop?: number | undefined;
    stickyBottom?: number | undefined;
    outlineColor?: string | undefined;
    outlineRadius?: number | undefined;
    statusBarPadding?: boolean | undefined;
    safeAreaView?: boolean | undefined;
    spaceBetween?: boolean | undefined;
    spaceAround?: boolean | undefined;
    spaceEvenly?: boolean | undefined;
    modalBox?: boolean | undefined;

    style?: CSSProperties | CSSProperties [];
    backgroundImage?: Property.BackgroundImage;
    backgroundSize?: Property.BackgroundSize;
    backgroundRepeat?: Property.BackgroundRepeat;
    backgroundBlendMode?: Property.BackgroundBlendMode;
    marginHorizontal?: number | string | undefined;
    marginVertical?: number | string | undefined;
    paddingHorizontal?: number | string | undefined;
    paddingVertical?: number | string | undefined;
};

export type TStyleExtendedViewProps = Omit<React.HTMLAttributes<HTMLDivElement>, "style"> & CSSProperties & StyleExtendedViewProps;

export const viewPropsToStyles = (originProps: TStyleExtendedViewProps) =>
{
    const props = { ...originProps };
    
    const autoScale = 'autoScale' in props && props.autoScale != null ? props.autoScale : getDefaultAutoScale();

    const applyScale = (value: number | string) : number => 
    { 
        const val : number = typeof value == 'string' ? parseInt(value) : value;

        return autoScale ? scale(val) : val;
    }

    let paddingTop = 0;

    if('statusBarPadding' in props && props?.statusBarPadding != false) paddingTop = getStatusBarHeight() || 0;

    const styles : CSSProperties = {};

    // view
    if(props?.backgroundColor != null) { styles.backgroundColor = props.backgroundColor; delete props.backgroundColor; }
    if(props?.backgroundTransparent != null) { styles.backgroundColor = 'rgba(0,0,0,0)'; delete props.backgroundTransparent; }
    if(props?.borderBottomColor != null) { styles.borderBottomColor = props.borderBottomColor; delete props.borderBottomColor; }
    if(props?.borderBottomLeftRadius != null) { styles.borderBottomLeftRadius = applyScale(props.borderBottomLeftRadius); delete props.borderBottomLeftRadius; }
    if(props?.borderBottomRightRadius != null) { styles.borderBottomRightRadius = applyScale(props.borderBottomRightRadius); delete props.borderBottomRightRadius; }
    if(props?.borderBottomWidth != null) { styles.borderBottomWidth = applyScale(props.borderBottomWidth); delete props.borderBottomWidth; }
    if(props?.borderBottomStyle != null) { styles.borderBottomStyle = props?.borderBottomStyle; delete props.borderBottomStyle; }
    if(props?.borderColor != null) { styles.borderColor = props.borderColor; delete props.borderColor; }
    if(props?.borderLeftColor != null) { styles.borderLeftColor = props.borderLeftColor; delete props.borderLeftColor; }
    if(props?.borderLeftWidth != null) { styles.borderLeftWidth = applyScale(props.borderLeftWidth); delete props.borderLeftWidth; }
    if(props?.borderLeftStyle != null) { styles.borderLeftStyle = props?.borderLeftStyle; delete props.borderLeftStyle; }
    if(props?.borderRadius != null) { styles.borderRadius = applyScale(props.borderRadius); delete props.borderRadius; }
    if(props?.borderRightColor != null) { styles.borderRightColor = props.borderRightColor; delete props.borderRightColor; }
    if(props?.borderRightWidth != null) { styles.borderRightWidth = applyScale(props.borderRightWidth); delete props.borderRightWidth; }
    if(props?.borderRightStyle != null) { styles.borderRightStyle = props?.borderRightStyle; delete props.borderRightStyle; }
    if(props?.borderStyle != null) { styles.borderStyle = props.borderStyle; delete props.borderStyle; }
    if(props?.borderTopColor != null) { styles.borderTopColor = props.borderTopColor; delete props.borderTopColor; }
    if(props?.borderTopLeftRadius != null) { styles.borderTopLeftRadius = applyScale(props.borderTopLeftRadius); delete props.borderTopLeftRadius; }
    if(props?.borderTopRightRadius != null) { styles.borderTopRightRadius = applyScale(props.borderTopRightRadius); delete props.borderTopRightRadius; }
    if(props?.borderTopWidth != null) { styles.borderTopWidth = applyScale(props.borderTopWidth); delete props.borderTopWidth; }    
    if(props?.borderTopStyle != null) { styles.borderTopStyle = props?.borderTopStyle; delete props.borderTopStyle; }
    if(props?.borderWidth != null) { styles.borderWidth = applyScale(props.borderWidth); delete props.borderWidth; }
    if(props?.opacity != null) { styles.opacity = props.opacity; delete props.opacity; }
    if(props?.outlineColor != null) { styles.borderColor = props.outlineColor; if(!styles.borderStyle) styles.borderStyle = 'solid'; delete props.outlineColor; }
    if(props?.outlineRadius != null) { styles.borderRadius = applyScale(props.outlineRadius); delete props.outlineRadius; }

    if(props?.fillParentWidth != null) { styles.width = "100%"; delete props.fillParentWidth; }
    if(props?.fillParentHeight != null) { styles.height = "100%"; delete props.fillParentHeight; }
    if(props?.fillParent != null) { styles.width = "100%"; styles.height = "100%"; delete props.fillParent; }
    if(props?.fillViewPortWidth != null) { styles.width = "100vw"; delete props.fillViewPortWidth; }
    if(props?.fillViewPortHeight != null) { styles.height = "100vh"; delete props.fillViewPortHeight; }
    if(props?.fillViewPort != null) { styles.width = "100vw"; styles.height = "100vh"; delete props.fillViewPort; }
    if(props?.fillViewPortAutoResize != null) { styles.width = "100vw"; styles.height = "100vh"; delete props.fillViewPortAutoResize; }

    // flex
    if('horizontal' in props)
    {
        styles.flexDirection = 'row';
        styles.display = 'flex';
        styles.flexWrap = 'nowrap';

        delete props.horizontal;
    }

    if('vertical' in props)
    {
        styles.flexDirection = 'column';
        styles.display = 'flex';        
        styles.flexWrap = 'nowrap';

        delete props.vertical;
    }

    if('absolute' in props) { styles.position = 'absolute'; delete props.absolute; }
    if('relative' in props) { styles.position = 'relative'; delete props.relative; }
    if('fixed' in props) { styles.position = 'absolute'; delete props.fixed; }
    if('sticky' in props) { styles.position = 'sticky'; delete props.sticky; }
    if('stickyTop' in props) { styles.position = 'sticky'; styles.top = props?.stickyTop || 0; delete props.stickyTop; }
    if('stickyBottom' in props) { styles.position = 'sticky'; styles.bottom = props?.stickyBottom || 0; delete props.stickyBottom; }

    if('absoluteFillParent' in props) { styles.position = 'absolute'; styles.left = 0; styles.right = 0; styles.top = 0; styles.bottom = 0; delete props.absoluteFillParent; }
    if('fixedFillParent' in props) { styles.position = 'fixed'; styles.left = 0; styles.right = 0; styles.top = 0; styles.bottom = 0; delete props.fixedFillParent; }

    if(props?.position != null) { styles.position = props.position; delete props.position; }

    if('flex' in props) 
    {
        styles.display = 'flex';

        if(props?.flex) styles.flex = props.flex;

        delete props.flex;
    }

    if(props?.flexBasis != null) { styles.flexBasis = props.flexBasis; delete props.flexBasis; }
    if(props?.flexDirection != null) { styles.flexDirection = props.flexDirection; delete props.flexDirection; }
    if(props?.flexGrow != null) { styles.flexGrow = props.flexGrow; delete props.flexGrow; }
    if(props?.flexShrink != null) { styles.flexShrink = props.flexShrink; delete props.flexShrink; }
    if(props?.flexWrap != null) { styles.flexWrap = props.flexWrap; delete props.flexWrap; }
    if(props?.alignContent != null) { styles.alignContent = props.alignContent; delete props.alignContent;}
    if(props?.alignItems != null) { styles.alignItems = props.alignItems; delete props.alignItems; }
    if(props?.alignSelf != null) { styles.alignSelf = props.alignSelf; delete props.alignSelf; }
    if(props?.aspectRatio != null) { styles.aspectRatio = props.aspectRatio; delete props.aspectRatio; }
    if(props?.display != null) { styles.display = props.display; delete props.display;}
    if(props?.justifyContent != null) { styles.justifyContent = props.justifyContent; delete props.justifyContent;}
    if(props?.margin != null) { styles.margin = applyScale(props.margin); delete props.margin; }
    if(props?.marginBottom != null) { styles.marginBottom = applyScale(props.marginBottom); delete props.marginBottom; }
    if(props?.marginHorizontal != null) { styles.marginLeft = styles.marginRight = applyScale(props.marginHorizontal); delete props.marginHorizontal; }
    if(props?.marginLeft != null) { styles.marginLeft = applyScale(props.marginLeft); delete props.marginLeft; }
    if(props?.marginRight != null) { styles.marginRight = applyScale(props.marginRight); delete props.marginRight; }
    if(props?.marginTop != null) { styles.marginTop = applyScale(props.marginTop); delete props.marginTop; }
    if(props?.marginVertical != null) { styles.marginTop = styles.marginBottom = applyScale(props.marginVertical); delete props.marginVertical; }
    if(props?.mb != null) { styles.marginBottom = applyScale(props.mb); delete props.mb; }
    if(props?.mh != null) { styles.marginLeft = styles.marginRight = applyScale(props.mh); delete props.mh; }
    if(props?.ml != null) { styles.marginLeft = applyScale(props.ml); delete props.ml; }
    if(props?.mr != null) { styles.marginRight = applyScale(props.mr); delete props.mr; }
    if(props?.mt != null) { styles.marginTop = applyScale(props.mt); delete props.mt; }
    if(props?.mv != null) { styles.marginTop = styles.marginBottom = applyScale(props.mv); delete props.mv; }
    if(props?.maxHeight != null) { styles.maxHeight = applyScale(props.maxHeight); delete props.maxHeight; }
    if(props?.maxWidth != null) { styles.maxWidth = applyScale(props.maxWidth); delete props.maxWidth; }
    if(props?.minHeight != null) { styles.minHeight = applyScale(props.minHeight); delete props.minHeight; }
    if(props?.minWidth != null) { styles.minWidth = applyScale(props.minWidth); delete props.minWidth; }
    if(props?.overflow != null) { styles.overflow = props.overflow; delete props.overflow; }
    if(props?.padding != null) { styles.padding = applyScale(props.padding); styles.paddingTop = paddingTop + applyScale(props.padding); delete props.padding; }
    if(props?.paddingBottom != null) { styles.paddingBottom = applyScale(props.paddingBottom); delete props.paddingBottom; }
    if(props?.paddingHorizontal != null) { styles.paddingLeft = styles.paddingRight = applyScale(props.paddingHorizontal); delete props.paddingHorizontal; }
    if(props?.paddingLeft != null) { styles.paddingLeft = applyScale(props.paddingLeft); delete props.paddingLeft; }
    if(props?.paddingRight != null) { styles.paddingRight = applyScale(props.paddingRight); delete props.paddingRight; }
    if(props?.paddingTop != null) { styles.paddingTop = paddingTop + applyScale(props.paddingTop); delete props.paddingTop; }
    if(props?.paddingVertical != null) { styles.paddingTop = paddingTop + applyScale(props.paddingVertical); styles.paddingBottom = applyScale(props.paddingVertical); delete props.paddingVertical; }
    if(props?.pb != null) { styles.paddingBottom = applyScale(props.pb); delete props.pb; }
    if(props?.ph != null) { styles.paddingLeft = styles.paddingRight = applyScale(props.ph); delete props.ph; }
    if(props?.pl != null) { styles.paddingLeft = applyScale(props.pl); delete props.pl; }
    if(props?.pr != null) { styles.paddingRight = applyScale(props.pr); delete props.pr; }
    if(props?.pt != null) { styles.paddingTop = paddingTop + applyScale(props.pt); delete props.pt; }
    if(props?.pv != null) { styles.paddingTop = paddingTop + applyScale(props.pv); styles.paddingBottom = applyScale(props.pv); delete props.pv; }
    if(props?.width != null) { styles.width = applyScale(props.width); delete props.width; }
    if(props?.height != null) { styles.height = applyScale(props.height); delete props.height; }
    if(props?.zIndex != null) { styles.zIndex = props.zIndex; delete props.zIndex; }
    if(props?.boxShadow != null) { styles.boxShadow = props.boxShadow; delete props.boxShadow; }
    if(paddingTop && !styles.paddingTop) styles.paddingTop = paddingTop;

    const isVertical = styles.flexDirection == 'row' ? false : true;

    // check align
    if(props?.align)
    {
        const aligns = props.align.split('|');

        for(let align of aligns)
        {
            // align horizontal
            if(align === 'left')
            {
                if(isVertical) styles.alignItems = 'flex-start';
                else styles.justifyContent = 'flex-start';        
            }

            else if(align === 'center')
            {
                if(isVertical) styles.alignItems = 'center';
                else styles.justifyContent = 'center';        
            }

            else if(align === 'right')
            {
                if(isVertical) styles.alignItems = 'flex-end';
                else styles.justifyContent = 'flex-end';        
            }            

            // align vertical
            else if(align === 'top')
            {
                if(isVertical) styles.justifyContent = 'flex-start';
                else styles.alignItems = 'flex-start';        
            }

            else if(align === 'middle')
            {
                if(isVertical) styles.justifyContent = 'center';
                else styles.alignItems = 'center';        
            }

            else if(align === 'bottom')
            {
                if(isVertical) styles.justifyContent = 'flex-end';
                else styles.alignItems = 'flex-end';        
            }
        }        
    }

    if(props?.spaceBetween) { styles.justifyContent = 'space-between'; delete props.spaceBetween; }
    if(props?.spaceAround) { styles.justifyContent = 'space-around'; delete props.spaceAround; }
    if(props?.spaceEvenly) { styles.justifyContent = 'space-evenly'; delete props.spaceEvenly; }

    if(props?.l != null) { styles.left = applyScale(props.l); delete props.l; }
    if(props?.r != null) { styles.right = applyScale(props.r); delete props.r; }
    if(props?.t != null) { styles.top = applyScale(props.t); delete props.t; }
    if(props?.b != null) { styles.bottom = applyScale(props.b); delete props.b; }

    if(props?.left != null) { styles.left = applyScale(props.left); delete props.left; }
    if(props?.right != null) { styles.right = applyScale(props.right); delete props.right; }
    if(props?.top != null) { styles.top = applyScale(props.top); delete props.top; }
    if(props?.bottom != null) { styles.bottom = applyScale(props.bottom); delete props.bottom; }

    if(props?.rowGap != null) { styles.rowGap = applyScale(props.rowGap); delete props.rowGap; }
    if(props?.columnGap != null) { styles.columnGap = applyScale(props.columnGap); delete props.columnGap; }

    if(props?.backgroundImage != null) { styles.backgroundImage = props.backgroundImage; delete props.backgroundImage; };
    if(props?.backgroundSize != null) { styles.backgroundSize = props.backgroundSize; delete props.backgroundSize; };
    if(props?.backgroundRepeat != null) { styles.backgroundRepeat = props.backgroundRepeat; delete props.backgroundRepeat; };
    if(props?.backgroundBlendMode != null) { styles.backgroundBlendMode = props.backgroundBlendMode; delete props.backgroundBlendMode; };

    if(props?.userSelect != null) { styles.userSelect = props.userSelect; delete props.userSelect; };
    if(props?.verticalAlign != null) { styles.verticalAlign = props.verticalAlign; delete props.verticalAlign; };
    
    if(props?.transform != null) { styles.transform = props.transform; delete props.transform; };
    if(props?.transformBox != null) { styles.transformBox = props.transformBox; delete props.transformBox; };
    if(props?.transformOrigin != null) { styles.transformOrigin = props.transformOrigin; delete props.transformOrigin; };
    if(props?.transformStyle != null) { styles.transformStyle = props.transformStyle; delete props.transformStyle; };
    
    if(props?.transition) 
    { 
        styles.transition = props.transition; 
        styles.WebkitTransition = props.transition; 
        styles.MozTransition = props.transition; 
        delete props.transition; 
    };
    if(props?.transitionBehavior != null) { styles.transitionBehavior = props.transitionBehavior; delete props.transitionBehavior; };
    if(props?.transitionDelay != null) { styles.transitionDelay = props.transitionDelay; delete props.transitionDelay; };
    if(props?.transitionDuration != null) { styles.transitionDuration = props.transitionDuration; delete props.transitionDuration; };
    if(props?.transitionProperty != null) { styles.transitionProperty = props.transitionProperty; delete props.transitionProperty; };
    if(props?.transitionTimingFunction != null) { styles.transitionTimingFunction = props.transitionTimingFunction; delete props.transitionTimingFunction; };

    if(props?.translate != null) { styles.translate = props.translate; delete props.translate; };

    if(props?.scrollbarWidth != null) { styles.scrollbarWidth = props.scrollbarWidth; delete props.scrollbarWidth; };

    return {
        props,
        styles
    }
};

export const addPropsToChildren = (props: { [key: string]: any }, children: any) => 
{
    if(!children || props == null || Object.keys(props).length < 1) return children;

    return React.Children.map(children, (child) => 
    {
        if(React.isValidElement(child) && props != null && typeof child?.type != 'string')
        {            
            const inheritProps = {};

            for(const keyCur in props)
            {
                if(child.props[keyCur] == null) inheritProps[keyCur] = props[keyCur];
            }

            return Object.keys(inheritProps).length > 0 ? React.cloneElement<any>(child, inheritProps) : child;
        }
        else return child;
    });
};

export const hcomponentPropKeys = ['autoScale'];

export const inheritHComponentPropsToChildren = (parentProps: any, children: any) =>
{
    if(!parentProps) return children;   

    const inheritProps = {};

    for(const keyCur of hcomponentPropKeys)
    {
        if(parentProps[keyCur] != null) inheritProps[keyCur] = parentProps[keyCur];
    }

    return Object.keys(inheritProps).length > 0 ? addPropsToChildren(inheritProps, children) : children;
};

const StyleExtendedView: React.FunctionComponent<TStyleExtendedViewProps> = (originProps) =>
{
    const { props, styles } = viewPropsToStyles(originProps);    

    const [viewPortSize, setViewPortSize] = useState(undefined);

    useEffect(() => 
    {
        const handleResize = originProps?.fillViewPortAutoResize ? () => {
            setViewPortSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        } : null;

        if(handleResize)
        {
            window.addEventListener('resize', handleResize);
            handleResize();
        }

        return () => {
            if(handleResize) window.removeEventListener('resize', handleResize);
        }        
    }, [originProps?.fillViewPortAutoResize])
    
    const onTouchEnd = props?.modalBox ? (e: any) => {        
        if(props?.onTouchEnd) props.onTouchEnd(e); 
        e.stopPropagation();
    } : props?.onTouchEnd;

    const { divRef, children, ...restProps } = props;

    if(viewPortSize && styles.width != viewPortSize.width) styles.width = viewPortSize.width;
    if(viewPortSize && styles.height != viewPortSize.height) styles.height = viewPortSize.height;

    for(const keyCur of hcomponentPropKeys) delete restProps[keyCur];

    return (
        <div {...restProps} ref={props?.divRef} onTouchEnd={onTouchEnd} style={mergeStyles(styles, props.style)}>
            {children ? inheritHComponentPropsToChildren(props, children) : null}
        </div>
    )
};

export default StyleExtendedView;