import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { atom, useSetRecoilState } from "recoil";
import { mergeProps, mergeStyles, scale } from "@hlibs/react/hstyles";
import * as Utils from "@hlibs//utils";
import { Animated } from "react-animated-css";
import Spacing from "../components/Spacing";
import View from '../components/StyleExtendedView';

export type TToastProps = React.HTMLAttributes<HTMLDivElement> & {
  customPreset?: TPreset;
  startTime?: Date | string;
  duration?: number;
  message?: string;
  messageElement?: ReactElement<any, any>;
  fadeInOutDuration?: number;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  icon?: ReactElement<any, any>;
  iconSpacing?: number;
  onShow?: () => void;
  onHide?: () => void;
};

export type TPreset = {
  props?: TToastProps;
  boxStyle?: CSSProperties;
  textStyle?: CSSProperties;
};

export const DefaultPreset: TPreset = {
  props: {
    message: "",
    iconSpacing: 8,
    duration: 3000,
    fadeInOutDuration: 250,
  },
  boxStyle: {
    position: "relative",
    paddingLeft: scale(20),
    paddingRight: scale(20),
    paddingTop: scale(12),
    paddingBottom: scale(15),
    borderRadius: scale(12),
    userSelect: "none",
    pointerEvents: "none",
    wordBreak: "break-all",
    textAlign: "left",
    alignSelf: 'stretch',
    backgroundColor: "#444444" + Utils.opacity2hex(0.85),
    marginBottom: 100,
  },
  textStyle: {
    color: '#ffffff',
    fontSize: 13,
    verticalAlign: 'center'
  },  
};

const PREFIX = "Hlibs_";

export type TToastData = {
  visible: boolean;
  props?: TToastProps;
};

export const initialStates: TToastData = { visible: false };

export const toastDataAtom = atom<TToastData>({
  key: PREFIX + "toastDataAtom" + Utils.createRandomString(10),
  default: initialStates,
});

export const useToast = () => {
  const setStates = useSetRecoilState(toastDataAtom);

  const show = (props: TToastProps) => {
    setStates({
      visible: true,
      props: { ...props, startTime: Utils.DateUtil.now() },
    });
  };

  const hide = () => {
    setStates(initialStates);
  };

  return {
    show,
    hide,
  };
};

const Toast: React.FunctionComponent<TToastProps> = (props) => {
  const { customPreset } = props;

  const selectedPreset = customPreset || DefaultPreset;

  const isDefaultPreset = selectedPreset == DefaultPreset ? true : false;

  const mergedProps: TToastProps = mergeProps(
    isDefaultPreset ? null : DefaultPreset.props,
    selectedPreset.props,
    props
  );

  const {
    startTime,
    duration,
    message,
    messageElement,
    onShow,
    onHide,
    icon,
    iconSpacing,
  } = mergedProps;

  const boxStyle: CSSProperties = mergeStyles(
    isDefaultPreset ? null : DefaultPreset.boxStyle,
    selectedPreset.boxStyle,
    mergedProps.style,
  );

  const textStyle: CSSProperties = mergeStyles(
    isDefaultPreset ? null : DefaultPreset.textStyle,
    selectedPreset.textStyle,
    mergedProps.textStyle,
  );

  const [timer, setTimer] = useState<any>(null);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);

      setTimer(null);
    }

    setVisible(true);

    if (onShow) onShow();

    const timerId = setTimeout(() => {
      setVisible(false);

      const hideTimerId = setTimeout(() => {
        if (onHide) onHide();
      }, mergedProps.fadeInOutDuration);

      setTimer(hideTimerId);
    }, duration);

    setTimer(timerId);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [startTime]);

  return (
    // @ts-ignore
    <Animated
      animationIn="fadeIn"
      animationInDuration={mergedProps.fadeInOutDuration}
      animationOut="fadeOut"
      animationOutDuration={mergedProps.fadeInOutDuration}
      isVisible={visible}
      style={boxStyle}      
    >
      <View horizontal align="left|top" style={textStyle}>
        {icon}
        {icon && iconSpacing > 0 ? <Spacing x={iconSpacing} /> : null}
        {messageElement ? messageElement : message}
      </View>
    </Animated>
  );
};

export default Toast;
