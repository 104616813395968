import { atom, RecoilState } from 'recoil';
import { Utils } from '@hlibs/index';
import { TResponsiveType } from '@hlibs/react-web/hook/windows';

const PREFIX = 'MEMORY_ATOMS_';

export type TDeviceInfo = { 
    width: number, 
    height: number,
    type: TResponsiveType,
};

export type TMemoryAtomList = {
    deviceInfo: RecoilState<TDeviceInfo>;
    showModalLogin: RecoilState<boolean>;
};

export type { TResponsiveType };

// 메모리에만 올려두고 사용 할 atoms
const memoryAtoms: TMemoryAtomList = {
    deviceInfo: atom<TDeviceInfo>({
        key: PREFIX + 'deviceInfo' + Utils.createRandomString(10),
        default: undefined,
    }),    
    showModalLogin: atom<boolean>({
        key: PREFIX + 'showModalLogin' + Utils.createRandomString(10),
        default: undefined,
    }),  
};

export default memoryAtoms;