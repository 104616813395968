import React from 'react';

export const isReactNative = false;

let _devScreenWidth = 1680;
let _devScreenHeight = 1080;

let _defaultAutoScale = false;

export const getDefaultAutoScale = () => _defaultAutoScale;

export const setDefaultAutoScale = (autoScale: boolean) => { _defaultAutoScale = autoScale; }

// 개발 기준 Screen Width
export const getDevScreenWidth = () => _devScreenWidth;
export const getDevScreenHeight = () => _devScreenHeight;

export const setDevScreenSize = (width: number, height?: number) => {
    _devScreenWidth = width;
    if(height) _devScreenHeight = height;
};

let _publishScreenWidth = 1680;
let _publishScreenHeight = undefined;

export const getPublishScreenWidth = () => _publishScreenWidth;
export const getPublishScreenHeight = () => _publishScreenHeight;

export const setPublishScreenSize = (width: number, height?: number) => {
    _publishScreenWidth = width;
    if(height) _publishScreenHeight = height;
};

export const isIos = false;
export const isAndroid = false;
export const isWeb = true;

export const screenHeight = null;
export const screenWidth = null;

export const statusBarHeight = null;

export type TScaleFactorBase = 'width' | 'height' | 'auto';

let _scaleFactorBase: TScaleFactorBase = 'width';

export const setScaleFactorBase = (base: TScaleFactorBase) => {
    _scaleFactorBase = base;
}

export const getScaleFactor = (base?: TScaleFactorBase) => {
    const scaleFactorBase = base || _scaleFactorBase;
    const scaleFactorWidth = _publishScreenWidth && _devScreenWidth ? _publishScreenWidth / _devScreenWidth : 1;
    if(scaleFactorBase == 'width') return scaleFactorWidth;
    const scaleFactorHeight = _publishScreenHeight && _devScreenHeight ? _publishScreenHeight / _devScreenHeight : 1;
    if(scaleFactorBase == 'height') return scaleFactorHeight;
    if(!_publishScreenWidth || !_publishScreenHeight) return 1;
    return (_publishScreenHeight / _publishScreenWidth) < (_devScreenHeight / _devScreenWidth) ? scaleFactorHeight : scaleFactorWidth;
};

export const getStatusBarHeight = () => null;


let _uniqueKeyCur = 0;

export const uniqueKey = () => { return 'uk-' + (++_uniqueKeyCur); };

// 폰 사이즈 체크 후 배율에 맞게 확대/축소
export const scale = (size: number | string, mul: number = 1, mulScaleFactor: boolean = true, reverseScaleFactor: boolean = false) : any =>
{
    const scaleFactor = mulScaleFactor ? getScaleFactor() : 1;

    if((!mul || mul == 1) && scaleFactor == 1) return size;

    const val : number = typeof size == 'string' ? parseInt(size) : size;

    const newSize = reverseScaleFactor ? val * mul / scaleFactor : val * mul * scaleFactor;

    return Math.round(newSize);
};

// Line Height
export const lineHeight = (lineHeight: number) =>{ return { lineHeight: scale(lineHeight) } };

// underline
export const underline = (color = '#000000') =>{ return { borderBottomWidth: 1, borderBottomColor: color, borderBottomStyle: 'solid' } };

// 스타일 합치기. 동일한 키의 스타일을 정의한 경우, 뒤쪽에 정의한 스타일로 덮어씌움.
// ex) mergeStyle(styleA, styleB, styleC)
// ex) mergeStyle([styleA, styleB], styleC)
export const mergeStyles = (...styles: any[]) =>
{
    let styleResult: any = {};

    for(let styleCur of styles)
    {
        if(styleCur == null)
            continue;
            
        if(Array.isArray(styleCur))
        {
            styleCur = mergeStyles(...styleCur);
        }

        styleResult = { ...styleResult, ...styleCur };
    }
    
    return styleResult;
};

// props 합치기
export const mergeProps = mergeStyles;

// color
export const color = (color: string) => { return { color } };


// shadow
export const shadow = (boxShadow: string = "2.8px 2.8px 2.8px 0 rgba(4, 0, 0, 0.2)") => { return { boxShadow }; };

export const sizeExt = {};

export const display = 
{
    none: () => { return { display: 'none' }; }, 
    flex: () => { return { display: 'flex' }; },
    inlineFlex: () => { return { display: 'inline-flex' }; },
    block: () => { return { display: 'block' }; }, 
    inlineBlock: () => { return { display: 'inline-block' }; }, 
};

export const cursor = 
{
    default: () => { return { cursor: "default" }; },
    auto: () => { return { cursor: "auto" }; },
    pointer: () => { return { cursor: "pointer" }; },
    contextMenu: () => { return { cursor: "context-menu" }; },
    help: () => { return { cursor: "help" }; },
    progress: () => { return { cursor: "progress" }; },
    wait: () => { return { cursor: "wait" }; },
    cell: () => { return { cursor: "cell" }; },
    crosshair: () => { return { cursor: "crosshair" }; },
    text: () => { return { cursor: "text" }; },
    verticalText: () => { return { cursor: "vertical-text" }; },
    alias: () => { return { cursor: "alias" }; },
    copy: () => { return { cursor: "copy" }; },
    move: () => { return { cursor: "move" }; },
    noDrop: () => { return { cursor: "no-drop" }; },
    notAllowed: () => { return { cursor: "not-allowed" }; },
    grab: () => { return { cursor: "grab" }; },
    grabbing: () => { return { cursor: "grabbing" }; },
    allScroll: () => { return { cursor: "all-scroll" }; },
    colResize: () => { return { cursor: "col-resize" }; },
    rowResize: () => { return { cursor: "row-resize" }; },
    nResize: () => { return { cursor: "n-resize" }; },
    eResize: () => { return { cursor: "e-resize" }; },
    sResize: () => { return { cursor: "s-resize" }; },
    wResize: () => { return { cursor: "w-resize" }; },
    neResize: () => { return { cursor: "ne-resize" }; },
    nwResize: () => { return { cursor: "nw-resize" }; },
    seResize: () => { return { cursor: "se-resize" }; },
    swResize: () => { return { cursor: "sw-resize" }; },
    ewResize: () => { return { cursor: "ew-resize" }; },
    nsResize: () => { return { cursor: "ns-resize" }; },
    neswResize: () => { return { cursor: "nesw-resize" }; },
    nwseResize: () => { return { cursor: "nwse-resize" }; },
    zoomIn: () => { return { cursor: "zoom-in" }; },
    zoomOut: () => { return { cursor: "zoom-out" }; },    
};

export const userSelect = 
{
    none: () => { return { userSelect: "none" }; },
    all: () => { return { userSelect: "all" }; },
    text: () => { return { userSelect: "text" }; },
};

export const pointerEvents =
{
    // https://developer.mozilla.org/ko/docs/Web/CSS/pointer-events

    none: () => { return { pointerEvents: "none" }; },
    auto: () => { return { pointerEvents: "auto" }; },
    text: () => { return { pointerEvents: "text" }; },
};
