import colors from './colors';

import { isReactNative, isIos, isAndroid, isWeb, getDevScreenWidth, getDevScreenHeight, screenHeight, screenWidth, getStatusBarHeight, getScaleFactor, scale, uniqueKey, mergeStyles, mergeProps, lineHeight, color, shadow, sizeExt, display, underline, cursor, userSelect } from '@hlibs/react-web/hstyles';

export { isReactNative, isIos, isAndroid, isWeb, getDevScreenWidth, getDevScreenHeight, screenHeight, screenWidth, getStatusBarHeight, getScaleFactor, scale, uniqueKey, mergeStyles, mergeProps, lineHeight, color, shadow, sizeExt, display, underline, cursor, userSelect };

// Letter Spacing
export const letterSpacing = (spacing: number) =>{ return { letterSpacing: scale(spacing) } };

// zIndex
export const zIndex = (zIndex: number) => { return { zIndex }};

// opacity
export const opacity = (opacity: number) => { return { opacity }};

// react only : https://developer.mozilla.org/ko/docs/Web/CSS/mix-blend-mode
export const mixBlendMode =
{
    normal: () => { return { mixBlendMode: 'normal' } },
    multiply: () => { return { mixBlendMode: 'multiply' } },
    screen: () => { return { mixBlendMode: 'screen' } },
    overlay: () => { return { mixBlendMode: 'overlay' } },
    darken: () => { return { mixBlendMode: 'darken' } },
    lighten: () => { return { mixBlendMode: 'lighten' } },
    colorDodge: () => { return { mixBlendMode: 'color-dodge' } },
    colorBurn: () => { return { mixBlendMode: 'color-burn' } },
    hardLight: () => { return { mixBlendMode: 'hard-light' } },
    softLight: () => { return { mixBlendMode: 'soft-light' } },
    difference: () => { return { mixBlendMode: 'difference' } },
    exclusion: () => { return { mixBlendMode: 'exclusion' } },
    hue: () => { return { mixBlendMode: 'hue' } },
    saturation: () => { return { mixBlendMode: 'saturation' } },
    color: () => { return { mixBlendMode: 'color' } },
    luminosity: () => { return { mixBlendMode: 'luminosity' } },    
}


// radius
export const radius = 
{
    all: (borderRadius: number, backgroundColor: any = null) => 
    {
        let style: any = { borderRadius: scale(borderRadius) };

        if(backgroundColor) style.backgroundColor = backgroundColor;

        return style;
    },
    left: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(borderRadius, 0, borderRadius, 0, backgroundColor); },
    top: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(borderRadius, borderRadius, 0, 0, backgroundColor); },
    right: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(0, borderRadius, 0, borderRadius, backgroundColor); },
    bottom: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(0, 0, borderRadius, borderRadius, backgroundColor); },
    leftTop: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(borderRadius, 0, 0, 0, backgroundColor); },
    rightTop: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(0, borderRadius, 0, 0, backgroundColor); },
    leftBottom: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(0, 0, borderRadius, 0, backgroundColor); },
    rightBottom: (borderRadius: number, backgroundColor: any = null) => { return radius.custom(0, 0, 0, borderRadius, backgroundColor); },
    custom: (leftTop: number, rightTop: number, leftBottom: number, rightBottom: number, backgroundColor: any = null) =>
    {
        let style: any = {};

        if(backgroundColor) style.backgroundColor = backgroundColor;
        if(leftTop) style.borderTopLeftRadius = scale(leftTop);
        if(rightTop) style.borderTopRightRadius = scale(rightTop);
        if(leftBottom) style.borderBottomLeftRadius = scale(leftBottom);
        if(rightBottom) style.borderBottomRightRadius = scale(rightBottom);

        return style;
    }
};

export const border =
{
    none: () => { return { borderStyle: 'none' }; },
    color: (borderColor: any) => { return { borderColor }; },
    width: (borderWidth: number) => { return { borderWidth: scale(borderWidth) }; },
    style: (borderStyle: any) => { return { borderStyle }; },

    styles: 
    {
        none: 'none',
        hidden: 'hidden',
        dotted: 'dotted',
        dashed: 'dashed',
        solid: 'solid',
        double: 'double',
        groove: 'groove',
        ridge: 'ridge',
        inset: 'inset',
        outset: 'outset',
    },
    top: (style = 'solid', color = '#000000', width = 1) => { return { borderTopWidth: scale(width), borderTopColor: color, borderTopStyle: style }},
    left: (style = 'solid', color = '#000000', width = 1) => { return { borderLeftWidth: scale(width), borderLeftColor: color, borderLeftStyle: style }},
    right: (style = 'solid', color = '#000000', width = 1) => { return { borderRightWidth: scale(width), borderRightColor: color, borderRightStyle: style }},
    bottom: (style = 'solid', color = '#000000', width = 1) => { return { borderBottomWidth: scale(width), borderBottomColor: color, borderBottomStyle: style }},

    topBottom: (style = 'solid', color = '#000000', width = 1) => { return { borderTopWidth: scale(width), borderTopColor: color, borderTopStyle: style, borderBottomWidth: scale(width), borderBottomColor: color, borderBottomStyle: style }},
    leftRight: (style = 'solid', color = '#000000', width = 1) => { return { borderLeftWidth: scale(width), borderLeftColor: color, borderLeftStyle: style, borderRightWidth: scale(width), borderRightColor: color, borderRightStyle: style }},

    all: (style = 'solid', color = '#000000', width = 1) => { return { borderWidth: scale(width), borderColor: color, borderStyle: style };},
};

export const outline = 
{
    none: () => { return { outline: 'none' }; },
    custom: (radius, borderColor = colors.white, borderWidth = 1, backgroundColor = colors.white, borderStyle = 'solid') =>
    {
        return { borderRadius: scale(radius), backgroundColor, borderStyle, borderWidth: scale(borderWidth), borderColor };
    }
};

export const padding = 
{
    left: (left, applyAutoScale = true) =>
    {
        return { paddingLeft: applyAutoScale ? scale(left) : left };
    },
    top: (top, applyAutoScale = true) =>
    {
        return { paddingTop: applyAutoScale ? scale(top) : top };
    },
    right: (right, applyAutoScale = true) =>
    {
        return { paddingRight: applyAutoScale ? scale(right) : right };
    },
    bottom: (bottom, applyAutoScale = true) =>
    {
        return { paddingBottom: applyAutoScale ? scale(bottom) : bottom };
    },
    topBottom: (top = 0, bottom = 0, applyAutoScale = true) =>
    {
        return { paddingTop: applyAutoScale ? scale(top) : top, paddingBottom: applyAutoScale ? scale(bottom) : bottom };
    },
    topBottomAll: (topBottom = 0, applyAutoScale = true) =>
    {
        return { paddingTop: applyAutoScale ? scale(topBottom) : topBottom, paddingBottom: applyAutoScale ? scale(topBottom) : topBottom };
    },
    vertical: (vertical = 0, applyAutoScale = true) =>
    {
        return { paddingTop: applyAutoScale ? scale(vertical) : vertical, paddingBottom: applyAutoScale ? scale(vertical) : vertical };
    },
    leftRight: (left = 0, right = 0, applyAutoScale = true) =>
    {
        return { paddingLeft: applyAutoScale ? scale(left) : left, paddingRight: applyAutoScale ? scale(right) : right };
    },
    leftRightAll: (leftRight = 0, applyAutoScale = true) =>
    {
        return { paddingLeft: applyAutoScale ? scale(leftRight) : leftRight, paddingRight: applyAutoScale ? scale(leftRight) : leftRight };
    },
    horizontal: (horizontal = 0, applyAutoScale = true) =>
    {
        return { paddingLeft: applyAutoScale ? scale(horizontal) : horizontal, paddingRight: applyAutoScale ? scale(horizontal) : horizontal };
    },
    all: (padding, applyAutoScale = true) =>
    {
        return { paddingLeft: applyAutoScale ? scale(padding) : padding, paddingTop: applyAutoScale ? scale(padding) : padding, 
            paddingRight: applyAutoScale ? scale(padding) : padding, paddingBottom: applyAutoScale ? scale(padding) : padding };
    },
    custom: (left = 0, top = 0, right = 0, bottom = 0, applyAutoScale = true) =>
    {
        return { paddingLeft: applyAutoScale ? scale(left) : left, paddingTop: applyAutoScale ? scale(top) : top, 
            paddingRight: applyAutoScale ? scale(right) : right, paddingBottom: applyAutoScale ? scale(bottom) : bottom };
    }
};


export const margin =
{
    horizontal: (horizontal, applyAutoScale = true) =>
    {
        return { marginHorizontal: applyAutoScale ? scale(horizontal) : horizontal };
    },
    vertical: (vertical, applyAutoScale = true) =>
    {
        return { marginVertical: applyAutoScale ? scale(vertical) : vertical };
    },
    left: (left, applyAutoScale = true) =>
    {
        return { marginLeft: applyAutoScale ? scale(left) : left };
    },
    top: (top, applyAutoScale = true) =>
    {
        return { marginTop: applyAutoScale ? scale(top) : top };
    },
    right: (right, applyAutoScale = true) =>
    {
        return { marginRight: applyAutoScale ? scale(right) : right };
    },
    bottom: (bottom, applyAutoScale = true) =>
    {
        return { marginBottom: applyAutoScale ? scale(bottom) : bottom };
    },
    topBottom: (top = 0, bottom = 0, applyAutoScale = true) =>
    {
        return { marginTop: applyAutoScale ? scale(top) : top, marginBottom: applyAutoScale ? scale(bottom) : bottom };
    },
    topBottomAll: (topBottom = 0, applyAutoScale = true) =>
    {
        return { marginTop: applyAutoScale ? scale(topBottom) : topBottom, marginBottom: applyAutoScale ? scale(topBottom) : topBottom };
    },
    leftRight: (left = 0, right = 0, applyAutoScale = true) =>
    {
        return { marginLeft: applyAutoScale ? scale(left) : left, marginRight: applyAutoScale ? scale(right) : right };
    },
    leftRightAll: (leftRight = 0, applyAutoScale = true) =>
    {
        return { marginLeft: applyAutoScale ? scale(leftRight) : leftRight, marginRight: applyAutoScale ? scale(leftRight) : leftRight };
    },
    all: (margin, applyAutoScale = true) =>
    {
        return { marginLeft: applyAutoScale ? scale(margin) : margin, marginTop: applyAutoScale ? scale(margin) : margin, 
            marginRight: applyAutoScale ? scale(margin) : margin, marginBottom: applyAutoScale ? scale(margin) : margin };
    },
    custom: (left = 0, top = 0, right = 0, bottom = 0, applyAutoScale = true) =>
    {
        return { marginLeft: applyAutoScale ? scale(left) : left, marginTop: applyAutoScale ? scale(top) : top, 
            marginRight: applyAutoScale ? scale(right) : right, marginBottom: applyAutoScale ? scale(bottom) : bottom };
    }    
};


export const size = 
{
    width: (width, applyAutoScale = true) => { return { width: applyAutoScale ? scale(width) : width }; },
    height: (height, applyAutoScale = true) => { return { height: applyAutoScale ? scale(height) : height }; },
    maxWidth: (maxWidth, applyAutoScale = true) => { return { maxWidth: applyAutoScale ? scale(maxWidth) : maxWidth }; },
    maxHeight: (maxHeight, applyAutoScale = true) => { return { maxHeight: applyAutoScale ? scale(maxHeight) : maxHeight }; },
    minWidth: (minWidth, applyAutoScale = true) => { return { minWidth: applyAutoScale ? scale(minWidth) : minWidth }; },
    minHeight: (minHeight, applyAutoScale = true) => { return { minHeight: applyAutoScale ? scale(minHeight) : minHeight }; },
    fillParent: () => { return { width: '100%', height: '100%' }; },
    fillParentWidth: () => { return { width: '100%' }; },
    fillParentHeight: () => { return { height: '100%' }; },
    all: (widthHeight, applyAutoScale = true) => { return size.custom(widthHeight, widthHeight, applyAutoScale); },
    custom: (width, height, applyAutoScale = true) =>
    {
        if(height == null) height = width;
        
        return { width: applyAutoScale ? scale(width) : width, height: applyAutoScale ? scale(height) : height };
    }, 

    ...sizeExt
};

export const width = size.width;

export const height = size.height;

export const backgroundColor = (backgroundColor) => { return { backgroundColor }; }

export const backgroundTransparent = () => { return { backgroundColor: colors.transparent }; }

// react 
export const backgroundImage = (url) => { return { backgroundImage: `url(${url})` } };

// react => backgroundSize : auto, cover, contain, '200px, 100px'
export const backgroundSize = (backgroundSize) => { return { backgroundSize } };


export const absolute = 
{
    left: (left = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', left: applyAutoScale ? scale(left) : left };
    },    
    top: (top = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', top: applyAutoScale ? scale(top) : top };
    },    
    right: (right = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', right: applyAutoScale ? scale(right) : right };
    },    
    bottom: (bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', bottom: applyAutoScale ? scale(bottom) : bottom };
    },    
    leftTop: (left = 0, top = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', left: applyAutoScale ? scale(left) : left, top: applyAutoScale ? scale(top) : top};
    },    
    rightTop: (right = 0, top = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', right: applyAutoScale ? scale(right) : right, top: applyAutoScale ? scale(top) : top};
    },    
    leftBottom: (left = 0, bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', left: applyAutoScale ? scale(left) : left, bottom: applyAutoScale ? scale(bottom) : bottom};
    },    
    rightBottom: (right = 0, bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', right: applyAutoScale ? scale(right) : right, bottom: applyAutoScale ? scale(bottom) : bottom};
    },    
    all: (left = 0, top = 0, right = 0, bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'absolute', left: applyAutoScale ? scale(left) : left, top: applyAutoScale ? scale(top) : top, right: applyAutoScale ? scale(right) : right, bottom: applyAutoScale ? scale(bottom) : bottom};
    },    
};

// react only : native 에서는 absolute 로 대체
export const fixed = isReactNative ? absolute :
{
    left: (left = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', left: applyAutoScale ? scale(left) : left};
    },    
    top: (top = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', top: applyAutoScale ? scale(top) : top};
    },    
    right: (right = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', right: applyAutoScale ? scale(right) : right};
    },    
    bottom: (bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', bottom: applyAutoScale ? scale(bottom) : bottom};
    },    
    leftTop: (left = 0, top = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', left: applyAutoScale ? scale(left) : left, top: applyAutoScale ? scale(top) : top};
    },    
    rightTop: (right = 0, top = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', right: applyAutoScale ? scale(right) : right, top: applyAutoScale ? scale(top) : top};
    },    
    leftBottom: (left = 0, bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', left: applyAutoScale ? scale(left) : left, bottom: applyAutoScale ? scale(bottom) : bottom};
    },    
    rightBottom: (right = 0, bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', right: applyAutoScale ? scale(right) : right, bottom: applyAutoScale ? scale(bottom) : bottom};
    },
    all: (left = 0, top = 0, right = 0, bottom = 0, applyAutoScale = true) =>
    {
        return {position: 'fixed', left: applyAutoScale ? scale(left) : left, top: applyAutoScale ? scale(top) : top, right: applyAutoScale ? scale(right) : right, bottom: applyAutoScale ? scale(bottom) : bottom};
    },    
};

export const ignoreLayout = absolute.all;

export const relative = () => { return { position: 'relative' } };

export const borderRadius = (borderRadius) =>
{
    return {borderRadius: scale(borderRadius)};
}

// react => flex: display == flex or inlineFlex 일 경우에 적용 됨
const flexBase =
{
    layout:
    {
        vertical: (flexWrap = false, reverse = false) => { return {flexDirection: 'column', flexWrap: flexWrap === false ? 'nowrap' : (reverse === true ? 'wrap-reverse' : 'wrap'), display: 'flex' }; },
        horizontal: (flexWrap = false, reverse = false) => { return {flexDirection: 'row', flexWrap: flexWrap === false ? 'nowrap' : (reverse === true ? 'wrap-reverse' : 'wrap'), display: 'flex' }; },
    
        flexDirection: 
        {
            row: () => { return { flexDirection: 'row' }; },
            rowReverse: () => { return { flexDirection: 'row-reverse' }; },
            column: () => { return { flexDirection: 'column' }; },
            columnReverse: () => { return { flexDirection: 'column-reverse' }; },
        },
        flexWrap: 
        {
            wrap: () => { return { flexWrap: 'wrap' }; },
            wrapReverse: () => { return { flexWrap: 'wrap-reverse' }; },
            nowrap: () => { return { flexWrap: 'nowrap' }; },
        }    
    },
    // 레이아웃 속성들
    // 가로/세로 중복 정렬시 네이밍 가로-세로 순서
    align: 
    {
        horizontal: 
        {
            // 가로축
            left: () => { return {justifyContent: 'flex-start'}; },
            right: () => { return {justifyContent: 'flex-end'}; },
            spaceBetween: () => { return {justifyContent: 'space-between'}; },
            spaceEvenly: () => { return {justifyContent: 'space-evenly'}; },
            spaceAround: () => { return {justifyContent: 'space-around'}; },
    
            // 세로축
            top: () => { return {alignItems: 'flex-start'}; },
            bottom: () => { return {alignItems: 'flex-end'}; },
            middle: () => { return {alignItems: 'center'}; },
            stretch: () => { return {alignItems: 'stretch'}; },
            baseline: () => { return {alignItems: 'baseline'}; },
    
            // 혼합
            center: () => { return {justifyContent: 'center', alignItems: 'center'}; },
    
            leftTop: () => { return {justifyContent: 'flex-start', alignItems: 'flex-start'}; },
            leftMiddle: () => { return {justifyContent: 'flex-start', alignItems: 'center'}; },
            leftBottom: () => { return {justifyContent: 'flex-start', alignItems: 'flex-end'}; },
    
            centerTop: () => { return {justifyContent: 'center', alignItems: 'flex-start'}; },
            centerBottom: () => { return {justifyContent: 'center', alignItems: 'flex-end'}; },
    
            rightTop: () => { return {justifyContent: 'flex-end', alignItems: 'flex-start'}; },
            rightMiddle: () => { return {justifyContent: 'flex-end', alignItems: 'center'}; },
            rightBottom: () => { return {justifyContent: 'flex-end', alignItems: 'flex-end'}; },
    
            custom: (justifyContent, alignItems) => { return {justifyContent, alignItems}; },
        },
        vertical: 
        {
            // 가로축
            left: () => { return {alignItems: 'flex-start'}; },
            right: () => { return {alignItems: 'flex-end'}; },
            spaceBetween: () => { return {alignItems: 'space-between'}; },
            spaceEvenly: () => { return {alignItems: 'space-evenly'}; },
            spaceAround: () => { return {alignItems: 'space-around'}; },
    
            // 세로축
            top: () => { return {justifyContent: 'flex-start'}; },
            middle: () => { return {justifyContent: 'center'}; },
            bottom: () => { return {justifyContent: 'flex-end'}; },
            stretch: () => { return {justifyContent: 'stretch'}; },
            baseline: () => { return {justifyContent: 'baseline'}; },
    
            // 혼합
            center: () => { return {alignItems: 'center', justifyContent: 'center'}; },
    
            leftTop: () => { return {alignItems: 'flex-start', justifyContent: 'flex-start'}; },
            leftMiddle: () => { return {alignItems: 'flex-start', justifyContent: 'center'}; },
            leftBottom: () => { return {alignItems: 'flex-start', justifyContent: 'flex-end'}; },
    
            centerTop: () => { return {alignItems: 'center', justifyContent: 'flex-start'}; },
            centerBottom: () => { return {alignItems: 'center', justifyContent: 'flex-end'}; },
    
            rightTop: () => { return {alignItems: 'flex-end', justifyContent: 'flex-start'}; },
            rightMiddle: () => { return {alignItems: 'flex-end', justifyContent: 'center'}; },
            rightBottom: () => { return {alignItems: 'flex-end', justifyContent: 'flex-end'}; },
    
            custom: (justifyContent, alignItems) => { return {justifyContent, alignItems}; },
        },
    },
    // items 속성들
    items:
    {
        flex: (flex = 1, flexGrow = null, flexShrink = null, flexBasis = "auto") => 
        { 
            if(flexGrow == null) flexGrow = flex;
            if(flexShrink == null) flexShrink = flex;

            return { flex, flexGrow, flexShrink, flexBasis }
        },
        order: (order = 0) => { return { order }; },
        grow: (flexGrow = 1) => { return { flexGrow }; },
        shrink: (flexShrink = 1) => { return { flexShrink }; },
        basis:
        {
            custom: (flexBasis) => { return { flexBasis }; },
            fill: () => { return { flexBasis: 'fill' }; },
            maxContent: () => { return { flexBasis: 'max-content' }; },
            minContent: () => { return { flexBasis: 'min-content' }; },
            fitContent: () => { return { flexBasis: 'fit-content' }; },
            content: () => { return { flexBasis: 'content' }; },
        },
        alignSelf: 
        {
            auto: () => { return { alignSelf: "auto" }; },
            stretch: () => { return { alignSelf: "stretch" }; },
            flexStart: () => { return { alignSelf: "flex-start" }; },
            flexEnd: () => { return { alignSelf: "flex-end" }; },
            center: () => { return { alignSelf: "center" }; },
            baseline: () => { return { alignSelf: "baseline" }; },
        },    
    },
};

// flex
export const flex = flexBase.items.flex;

export const flexBasis = flexBase.items.basis;

export const flexShrink = flexBase.items.shrink;

export const flexGrow = flexBase.items.grow;

export const flexAlignSelf = flexBase.items.alignSelf;


export const flexLayout = flexBase.layout;

export const flexItems = flexBase.items;

export const flexAlign = flexBase.align;

export const horizontalLayout = flexBase.layout.horizontal;
export const verticalLayout = flexBase.layout.vertical;

export const horizontalAlign = flexBase.align.horizontal;
export const verticalAlign = flexBase.align.vertical;

const fontSizeMul = 0.95;

export const text = 
{
    size: (fontSize) => { return { fontSize: scale(fontSize, fontSizeMul, true) }; },
    weight: (fontWeight = 'normal') => { return { fontWeight }; },
    lineHeight: (lineHeight) => { return lineHeight(lineHeight); },
    letterSpacing: (letterSpacing) => { return { letterSpacing: scale(letterSpacing) }; },
    color: (color) => { return { color }; },

    wordBreak:
    {
        normal: () => { return { wordBreak: 'normal' } },
        breakAll: () => { return { wordBreak: 'break-all' } },
        keepAll: () => { return { wordBreak: 'keep-all' } },
    },

    underline: () => { return underline(); },
    
    bold: (fontSize, color = colors.black, fontWeight = "bold") => text.custom(fontSize, color, fontWeight, 'Bold'),
    light: (fontSize, color = colors.black, fontWeight = "light") => text.custom(fontSize, color, fontWeight, 'Light'),
    medium: (fontSize, color = colors.black, fontWeight = "medium") => text.custom(fontSize, color, fontWeight, 'Medium'),
    regular: (fontSize, color = colors.black, fontWeight = "normal") => text.custom(fontSize, color, fontWeight, 'Regular'),

    custom: (fontSize, color = colors.black, fontWeight = "normal", fontFamily = 'Regular') => 
    {    
        if(color[0] != '#')
        {
            color = "#" + color;
        }

        fontSize = scale(fontSize, fontSizeMul, true);

        fontWeight = String(fontWeight);

        return { fontFamily, fontSize, color, fontWeight };
    },

    // 가로/세로 중복 정렬시 네이밍 가로-세로 순서
    align: 
    {
        auto: (lineHeight = null) => { return text.align.custom('auto', null, lineHeight); },
        left: (lineHeight = null) => { return text.align.custom('left', null, lineHeight); },
        right: (lineHeight = null) => { return text.align.custom('right', null, lineHeight); },
        center: (lineHeight = null) => { return text.align.custom('center', null, lineHeight); },
        justify: (lineHeight = null) => { return text.align.custom('justify', null, lineHeight); },

        top: (lineHeight = null) => { return text.align.custom(null, 'top', lineHeight); },
        middle: (lineHeight = null) => { return text.align.custom(null, 'middle', lineHeight); },
        bottom: (lineHeight = null) => { return text.align.custom(null, 'bottom', lineHeight); },

        centerMiddle: (lineHeight = null) => { return text.align.custom('center', 'middle', lineHeight); },

        leftTop: (lineHeight = null) => { return text.align.custom('left', 'top', lineHeight); },
        leftMiddle: (lineHeight = null) => { return text.align.custom('left', 'middle', lineHeight); },
        leftBottom: (lineHeight = null) => { return text.align.custom('left', 'bottom', lineHeight); },

        centerTop: (lineHeight = null) => { return text.align.custom('left', 'top', lineHeight); },
        centerBottom: (lineHeight = null) => { return text.align.custom('left', 'middle', lineHeight); },

        rightTop: (lineHeight = null) => { return text.align.custom('right', 'top', lineHeight); },
        rightMiddle: (lineHeight = null) => { return text.align.custom('right', 'middle', lineHeight); },
        rightBottom: (lineHeight = null) => { return text.align.custom('right', 'bottom', lineHeight); },

        custom: (horizontal, vertical = null, lineHeight = null) => 
        { 
            let styles: any = {};

            if(horizontal != null)
            {
                styles.textAlign = horizontal;
            }

            if(!isReactNative && vertical != null)
            {
                styles.verticalAlign = vertical;
                styles.textAlignVertical = vertical;
            }

            if(lineHeight != null)
            {
                styles.lineHeight = scale(lineHeight);
            }

            return styles; 
        }
    },
};

export const overflow = 
{
    visible: () => { return { overflow: "visible" }; },
    hidden: () => { return { overflow: "hidden" }; },
    scroll: () => { return { overflow: "scroll" }; },
    auto: () => { return { overflow: "auto" }; },   // react only
};

export const whiteSpace =
{
    normal: () => { return { whiteSpace: "normal" }; },
    nowrap: () => { return { whiteSpace: "nowrap" }; },
    pre: () => { return { whiteSpace: "pre" }; },
    preWrap: () => { return { whiteSpace: "pre-wrap" }; },
    preLine: () => { return { whiteSpace: "pre-line" }; },
    breakSpaces: () => { return { whiteSpace: "break-spaces" }; },
    
    inherit: () => { return { whiteSpace: "inherit" }; },
    initial: () => { return { whiteSpace: "initial" }; },
    unset: () => { return { whiteSpace: "unset" }; },
};

