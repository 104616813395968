import React, { CSSProperties } from 'react';
import { mergeStyles, getDefaultAutoScale, scale } from '../hstyles';

type TProps = {
    autoScale?: boolean | undefined;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    flex?: number;
    flexGrow?: number;
    flexShrink?: number;
    style?: CSSProperties;
};

const Spacing: React.FunctionComponent<TProps> = (props) => {

    const { x, y, width, height, flex, flexGrow, flexShrink, style } = props;

    const widthCur = width || x || 1;
    const heightCur = height || y || 1;

    const flexCur = flex || flexGrow;
    const flexGrowCur = flexGrow || flexCur;
    const flexShrinkCur = flexShrink || flexCur;

    const autoScale = 'autoScale' in props && props.autoScale != null ? props.autoScale : getDefaultAutoScale();

    const styles = [widthCur ? { width: autoScale ? scale(widthCur) : widthCur } : null, heightCur ? { height: autoScale ? scale(heightCur) : heightCur } : null, flexCur ? { flex: flexCur, flexGrow: flexGrowCur, flexShrink: flexShrinkCur } : null, style];

    return <div style={mergeStyles(styles)} />;
};

export default Spacing;
