import moment from 'moment';

const weekday = ['일', '월', '화', '수', '목', '금', '토'];

export default class DateUtil {
    static date2str = (date: Date, format: string = 'yyyy-MM-dd'): string => {
        if (!date || !format) return null;

        format = format.replace(/(M+|d+|h+|H+|m+|s+|pk+|p+|w+)/g, function (v) {
            const z = {
                M: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                H: date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
                m: date.getMinutes(),
                s: date.getSeconds(),
                pk: date.getHours() > 12 ? '오후' : '오전',
                p: date.getHours() > 12 ? 'PM' : 'AM',
                w: weekday[date.getDay()],
            };

            return v == 'p' || v == 'pk' || v == 'w' ? z[v] : ((v.length > 1 ? '0' : '') + eval(z[v.slice(-1)])).slice(-2);
        });

        return format.replace(/(y+)/g, function (v) {
            return date.getFullYear().toString().slice(-v.length);
        });
    };

    static dateTime2str = (date: Date, format: string = 'yyyy-MM-dd hh:mm:ss'): string => this.date2str(date, format);

    static now = (format: string | undefined = undefined) => {
        let dateResult = moment().toDate();

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addSeconds = (seconds: number, date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = new Date(dateFrom.getTime() + 1000 * seconds);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addMinutes = (minutes: number, date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = new Date(dateFrom.getTime() + 1000 * 60 * minutes);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addHours = (hours: number, date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = new Date(dateFrom.getTime() + 1000 * 60 * 60 * hours);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addDays = (days: number, date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = new Date(dateFrom.getTime() + 1000 * 60 * 60 * 24 * days);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addMonths = (months: number, date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = new Date(dateFrom);

        dateResult.setMonth(dateResult.getMonth() + months);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addYears = (years: number, date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = new Date(dateFrom.getTime() + 1000 * 60 * 60 * 24 * 365 * years);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getFirstDayInMonth = (date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        let dateResult = moment(this.date2str(dateFrom, 'yyyy-MM') + '-01').toDate();

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getLastDayInMonth = (date: Date | string | undefined = undefined, format: string | undefined = undefined) => {
        let dateFrom = date ? moment(date).toDate() : moment().toDate();

        dateFrom.setMonth(dateFrom.getMonth() + 1);

        let dateResult = moment(this.date2str(dateFrom, 'yyyy-MM') + '-01').toDate();

        dateResult.setDate(dateResult.getDate() - 1);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getWeekDayStr = (date: Date) => {
        return weekday[date.getDay()];
    };

    // startFromMonday == true ? 월요일부터 한주 : 일요일부터 한주
    static getWeekStartDate = (date: Date | string | undefined = undefined, startFromMonday: boolean = false, format: string | undefined = undefined) => {
        const weekdayAdd = startFromMonday ? [-6, 0, -1, -2, -3, -4, -5] : [0, -1, -2, -3, -4, -5, -6];

        let dateFrom = date ? new Date(date) : moment().toDate();

        let dateResult = this.addDays(weekdayAdd[dateFrom.getDay()], dateFrom);

        // @ts-ignore
        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getDiffMonths = (dateTo: Date, dateFrom: Date) => {
        return Math.floor(Math.abs(dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24 * 30));
    }

    static getDiffDays = (dateTo: Date, dateFrom: Date) => {
        return Math.floor(Math.abs(dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24));
    }
}
