import * as Config from '@hlibs/config';
import ExpireData from '@hlibs/expire-data';

export default class LocalStorage {
    static #_AES_SECRET = Config.CRYPTO.COOKIE_SECRET;

    static setSecret(secret: string) {
        this.#_AES_SECRET = secret;
    }

    static load(name: string) {
        return this.getItem(name);
    }

    static get(name: string) {
        return this.getItem(name);
    }

    static getItem(name: string) {
        const val = localStorage.getItem(name);

        if(!val) return null;

        const decVal = ExpireData.decrypt(val, this.#_AES_SECRET);

        try
        {
            if(decVal) return JSON.parse(decVal);
        }
        catch(e) {}

        return null;        
    }

    static save(name: string, data: any, expireSec: number | null = null) {
        return this.setItem(name, data, expireSec);
    }

    static set(name: string, data: any, expireSec: number | null = null) {
        return this.setItem(name, data, expireSec);
    }

    static setItem(name: string, data: any, expireSec: number | null = null) {
        if (data == null) {
            this.delete(name);
        } else {
            const dataEnc = ExpireData.encrypt(JSON.stringify(data), expireSec, this.#_AES_SECRET);

            localStorage.setItem(name, dataEnc);
        }
    }

    static delete(name: string) {
        localStorage.removeItem(name);
    }
}


