import CryptoJS from 'crypto-js';
import * as Config from './config';
import * as Debug from './debug';

const aes_secret: string = Config.CRYPTO.AES_SECRET;

const DefaultEncoder = CryptoJS.enc.Hex;

export const MD5 = (str: string, enc = DefaultEncoder) =>
{
    return CryptoJS.MD5(str).toString(enc);
};

export const SHA1 = (str: string, enc = DefaultEncoder) =>
{
    return CryptoJS.SHA1(str).toString(enc);
};

export const SHA256 = (str: string, enc = DefaultEncoder) =>
{    
    return CryptoJS.SHA256(str).toString(enc);
};

export const SHA512 = (str: string, enc = DefaultEncoder) =>
{
    return CryptoJS.SHA512(str).toString(enc);
};

export const SHA3 = (str: string, outLength: number = 512, enc = DefaultEncoder) =>
{
    return CryptoJS.SHA3(str, { outputLength: outLength }).toString(enc);
};

export const RIPEMD160 = (str: string, enc = DefaultEncoder) =>
{
    return CryptoJS.RIPEMD160(str).toString(enc);
};


export const AES = class
{
    static encrypt(str: string, key: string = aes_secret, iv: string | undefined = undefined)
    {
        return CryptoJS.AES.encrypt(str, key, iv ? { iv: CryptoJS.enc.Hex.parse(iv) } : undefined).toString();
    }

    static decrypt(str: string, key: string = aes_secret, iv: string | undefined = undefined, dec = CryptoJS.enc.Utf8)
    {
        let decrypted = CryptoJS.AES.decrypt(str, key, iv ? { iv: CryptoJS.enc.Hex.parse(iv) } : undefined);

        return decrypted.toString(dec);
    }
}

export const SHAAES = class
{
    static ivDefault = Config.CRYPTO.SHAAES_IV;

    static encrypt(str: string, key: string = aes_secret, iv: string | undefined = undefined)
    {
        let keyAes = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(key));

        return CryptoJS.AES.encrypt(str, keyAes, { iv: CryptoJS.enc.Hex.parse(iv ? iv : this.ivDefault) }).toString();
    }
    
    static decrypt(str: string, key: string = aes_secret, iv: string | undefined = undefined)
    {
        let keyAes = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(key));

        return CryptoJS.AES.decrypt(str, keyAes, { iv: CryptoJS.enc.Hex.parse(iv ? iv : this.ivDefault) }).toString(CryptoJS.enc.Utf8);
    }

    static encryptObject(obj: any, key: string = aes_secret, iv: string | undefined = undefined)
    {
        if(obj == null)
            return null;

        if(key == "")
        {
            return obj;
        }

        if(typeof obj == 'string')
        {
            return this.encrypt(obj, key, iv);
        }

        let objNew = {};

        try 
        {
            for(let keyCur in obj)
            {
                objNew[keyCur] = typeof obj[keyCur] == 'string' ? this.encrypt(obj[keyCur], key, iv) : this.encryptObject(obj[keyCur], key, iv);
            }
        } 
        catch (e) 
        {
            Debug.exception(e);
        }   

        return objNew;
    }

    static decryptObject(obj: any, key: string = aes_secret, iv: string | undefined = undefined)
    {
        if(obj == null)
            return null;

        if(key == "")
        {
            return obj;
        }

        if(typeof obj == 'string')
        {
            return this.decrypt(obj, key, iv);
        }

        let objNew = {};

        try 
        {
            for(let keyCur in obj)
            {
                objNew[keyCur] = typeof obj[keyCur] == 'string' ? this.decrypt(obj[keyCur], key, iv) : this.decryptObject(obj[keyCur], key, iv);
            }
        } 
        catch (e) 
        {
            Debug.exception(e);
        }   

        return objNew;
    }
}
