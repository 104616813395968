import React, { ReactElement, useEffect } from 'react';
import { atom, useRecoilState, useSetRecoilState, selector, useRecoilValue } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import Loading, { loadingDataAtom } from './Loading';
import Toast, { toastDataAtom } from './Toast';
import View, { TAlignType } from '../components/StyleExtendedView';
import * as Utils from '@hlibs//utils';

const PREFIX = 'Hlibs_';

let _zIndexCurrent = 10000;

type TModalStackData = Array<TModalState>;

const modalStackData: TModalStackData = [];

const initialStates: TModalStackData = [];

const modalStackAtom = atom<TModalStackData>({
    key: PREFIX + 'modalStackAtom' + Utils.createRandomString(10),
    default: initialStates,
});

type TModalParams = {
    element: ReactElement<any, any>;
    preventBackButton?: boolean;
    position?: TAlignType;
    backgroundColor?: string;
    tag?: string;
};

type TModalState = TModalParams & {
    id: string;
};

type TModalStackProps = {
    backgroundColor?: string;
    onClose?: () => any;
};

export class ModalStackInstance {
    static push(modal: TModalParams) {
        const id = Utils.sequencialUniqueKey('modal-stack');
        const itemCur = { ...modal, id };
        modalStackData.push(itemCur);
        setRecoil(modalStackAtom, [...modalStackData]);
        return id;
    }

    static pop() {
        if (modalStackData.length > 0) {
            modalStackData.pop();
            setRecoil(modalStackAtom, [...modalStackData]);
        }
    }

    static popById(id: string) {
        if (modalStackData.length > 0) {
            const newStack: Array<TModalState> = [];

            for (let itemCur of modalStackData) {
                if (itemCur.id === id) continue;
                newStack.push(itemCur);
            }

            if (modalStackData.length != newStack.length) {
                modalStackData.splice(0, modalStackData.length);
                for (let itemCur of newStack) {
                    modalStackData.push(itemCur);
                }
                setRecoil(modalStackAtom, [...modalStackData]);
                return true;
            }
        }

        return false;
    }

    static popAll() {
        modalStackData.splice(0, modalStackData.length);
        setRecoil(modalStackAtom, [...modalStackData]);
    }
}

export const getModalStack = () => {
    const push = (modal: TModalParams) => ModalStackInstance.push(modal);
    const pop = () => ModalStackInstance.pop();
    const popById = (id: string) => ModalStackInstance.popById(id);
    const popAll = () => ModalStackInstance.popAll();

    return {
        push,
        pop,
        popById,
        popAll,
    };
};

export const ModalStack: React.FunctionComponent<TModalStackProps> = (props) => {
    const [_modalStackData, setModalStackData] = useRecoilState(modalStackAtom);
    const [loadingStates, setLoadingStates] = useRecoilState(loadingDataAtom);
    const [toastStates, setToastStates] = useRecoilState(toastDataAtom);

    return (
        <>
            {
                modalStackData?.map((modalCurrent: TModalState, index: number) => (
                    modalCurrent ? (
                        <View
                            key={modalCurrent.id}
                            fixedFillParent 
                            zIndex={++_zIndexCurrent}
                        >
                            <View fillViewPort vertical align={modalCurrent?.position || 'center|middle'} flex={1} alignSelf='stretch' backgroundColor={!loadingStates.visible ? modalCurrent?.backgroundColor || props.backgroundColor || '#00000099' : 'rgba(0,0,0,0)'}>
                                {modalCurrent?.element}
                            </View>
                        </View>
                    ) : null        
                ))
            }
            {loadingStates.visible ? (
                <View fixedFillParent zIndex={Number.MAX_SAFE_INTEGER - 1} vertical>
                    <View flex={1} alignSelf='stretch'>
                        {loadingStates.visible ? <Loading {...loadingStates.props} /> : null}
                    </View>
                </View>
            ) : null}
            {toastStates.visible ? (
                <View stickyBottom={0} zIndex={Number.MAX_SAFE_INTEGER} vertical>
                    <View alignSelf='stretch' horizontal align='center'>
                        <Toast
                            {...toastStates.props}
                            onHide={() => {
                                setToastStates({ visible: false });
                            }}
                        />
                    </View>
                </View>
            ) : null}
        </>
    );
};

export default ModalStack;
